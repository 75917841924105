import React, { Component } from 'react';
import { NavLinksBar } from '../Navbar';

import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';

import '../Custom.css';
import {AllStyles} from "../styles/AllStyles";
import { Icon, Table } from 'semantic-ui-react';
import { Table as TableResp, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { Responsive } from 'semantic-ui-react';
import axios from "axios";
import moment from 'moment';
import {isMobile} from '../styles/AllStyles';
import Paper from "@material-ui/core/Paper";

const monthView = 'dayGridMonth';
const weekView = 'timeGridWeek';
const dayView = 'timeGridDay';

export default class Register extends Component {

    calendarRef = React.createRef();

    cultures = ['ko-KR'];
    theme1 = 'bootstrap';

    baseWidth = 780;
    baseLength = 600;

    state = {
        weekendsVisible: true,
        currentEvents: [],
        slideWidth: this.baseWidth,
        slideLength: this.baseLength
    }

    tableColor = 'violet';
    daysOfWeekTable = ['시간', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
    daysOfWeekTableMobile = ['시간', '월', '화', '수', '목', '금', '토'];
    amTimeTable = [<Icon name='heart outline' color={'violet'}/>,
                    <Icon name='star outline' color={'pink'}/>,
                    [<Icon name='heart outline' color={'violet'}/>, <Icon name='music' color={'teal'}/>],
                    // <>&#10084;</>,
                    <Icon name='star outline' color={'pink'}/>,
                    <Icon name='heart outline' color={'violet'}/>,
                    [<Icon name='star outline' color={'pink'}/>, <Icon name='music' color={'teal'}/>]];
    lunchTable = <Icon name='food'/>;
    pmTimeTable = [
        [<Icon name='star outline' color={'pink'}/>, <Icon name='music' color={'teal'}/>],
        [<Icon name='heart outline' color={'violet'}/>, <Icon name='star outline' color={'pink'}/>],
        [<Icon name='music' color={'teal'}/>],
        [<Icon name='heart outline' color={'violet'}/>, <Icon name='star outline' color={'pink'}/>],
        [<Icon name='star outline' color={'pink'}/>, <Icon name='music' color={'teal'}/>],
        ' ' ];

    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.getAll();
        // console.info("[Frontend] Calendar 1 historyDepth: " + JSON.stringify(historyDepth, null, 4));
    }

    componentDidMount() {
        this.updateDimensions();
    }

    componentWillMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    getAll = () => {
        if (isMobile()) {
            this.tableWidth = 500;
        }
        else {
            this.bigColumnWidth = 9;
        }
    }

    updateDimensions() {
        let currWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let currHeight = typeof window !== "undefined" ? window.innerHeight : 0;
        this.setState({currWidth: currWidth});
        this.setState({currHeight: currHeight});

        if (currWidth < this.baseWidth) {
            this.setState({
                slideWidth: currWidth-40,
                slideLength: (currWidth-40) / (this.baseWidth / this.baseLength)
            });
        }
        else {
            this.setState({
                slideWidth: this.baseWidth,
                slideLength: this.baseLength
            });
        }
        console.log("[Frontend] Home updateDimensions currWidth: " + currWidth + ", currHeight: " + currHeight);
    }

    render() {
        return (
            // <div className='demo-app'>
            <>
            <NavLinksBar/>

                {!isMobile() ?
                    <div className='demo-app-main' style={{...AllStyles.mainBody, fontSize: '20px', align: 'center',
                        marginLeft: '20%', marginRight: '20%'}} >
                        <div style={{...AllStyles.centerText, fontSize: '32px'}}><b>학부모 상담 및 개인 레슨 시간표 (예약제)</b></div>
                        <Table fixed color={this.tableColor} celled selectable textAlign={'center'} verticalAlign={'middle'}>
                            <Table.Header>
                                <Table.Row>
                                    {this.daysOfWeekTable.map((day) => (
                                        <Table.HeaderCell style={{backgroundColor: '#ffffcc'}}>{day}</Table.HeaderCell>))
                                    }

                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>오전<br/>10AM ~ 1PM</Table.Cell>
                                    {this.amTimeTable.map((day) => (
                                        <Table.Cell verticalAlign={'middle'}>{day}</Table.Cell>))
                                    }
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>점심<br/>1 ~ 2PM</Table.Cell>
                                    <Table.Cell colSpan='5' verticalAlign={'middle'}>
                                        {this.lunchTable}&#8195;점심시간&#8195;{this.lunchTable}</Table.Cell>
                                    <Table.Cell verticalAlign={'middle'}><Icon name='star outline' color={'pink'}/>
                                        <Icon name='music' color={'teal'}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>오후<br/>2 ~ 6PM</Table.Cell>
                                    {this.pmTimeTable.map((day) => (
                                        <Table.Cell verticalAlign={'middle'}>{day}</Table.Cell>))
                                    }
                                </Table.Row>

                            </Table.Body>
                        </Table>
                        <div style={{marginLeft: '120px'}}>
                            <Icon name='heart outline'/>&nbsp;&nbsp;안혜경 대표: 월요일 / 수요일 / 금요일 오전, 화요일 & 목요일 오후<br/>
                            <Icon name='star outline' color={'pink'}/>&nbsp;&nbsp;오유진 원장: 월요일 / 수요일 / 금요일 오후, 화요일 & 목요일 종일, 토요일 오전<br/>
                            <Icon name='music' color={'teal'}/>&nbsp;&nbsp;임민주 부원장: 수요일 오전, 월요일 / 수요일 / 금요일 오후, 토요일 오전
                        </div>
                    </div> :

                    // mobile layout
                    <div className='demo-app-main' style={{...AllStyles.mainBody, fontSize: '14px', align: 'center',
                        marginLeft: '10px', marginRight: '10px'}} >
                        <div style={{...AllStyles.centerText, fontSize: '16px', marginTop: '30px', marginBottom: '30px'}}>
                            <b>학부모 상담 및 개인 레슨 시간표 (예약제)</b></div>
                        <table className={"mobile"}>
                            <thead>
                                <tr>
                                    {this.daysOfWeekTableMobile.map((day) => (
                                        <th style={{backgroundColor: '#ffffcc'}}>{day}</th>))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <Td>오전<br/>10AM ~ 1PM</Td>
                                    {this.amTimeTable.map((day) => (
                                        <Td verticalAlign={'middle'}>{day}</Td>))
                                    }
                                </tr>
                                <tr>
                                    <td>점심<br/>1PM ~ 2PM</td>
                                    <td colSpan='5' verticalAlign={'middle'}>
                                        {this.lunchTable}점심시간{this.lunchTable}</td>
                                    {/*<td>{this.lunchTable}점심시간{this.lunchTable}</td>
                                    <td>{this.lunchTable}점심시간{this.lunchTable}</td>
                                    <td>{this.lunchTable}점심시간{this.lunchTable}</td>
                                    <td>{this.lunchTable}점심시간{this.lunchTable}</td>
                                    <td>{this.lunchTable}점심시간{this.lunchTable}</td>*/}
                                    <td verticalAlign={'middle'}><Icon name='star outline' color={'pink'}/>
                                        <Icon name='music' color={'teal'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>오후<br/>2PM ~ 6PM</td>
                                    {this.pmTimeTable.map((day) => (
                                        <td verticalAlign={'middle'}>{day}</td>))
                                    }
                                </tr>
                            </tbody>
                        </table>

                        <div style={{marginLeft: this.state.currWidth/2 - 60, marginTop: '10px' }}>
                            <Icon name='heart outline'/>&nbsp;안혜경 대표<br/>
                            <Icon name='star outline' color={'pink'}/>&nbsp;오유진 원장<br/>
                            <Icon name='music' color={'teal'}/>&nbsp;임민주 부원장
                        </div>
                    </div>
                }
                    <br/>

            </>
        )
    }
}
