import React, { Component } from 'react';
import { NavLinksBar } from '../Navbar';
//mport { INITIAL_EVENTS, createEventId } from '../pages/event-utils'
import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';

// import '../App.css';
// import '../Custom.css';
import {AllStyles} from "../styles/AllStyles";
import {isMobile} from '../styles/AllStyles';
// import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import moment from 'moment';
// import { Carousel } from "react-responsive-carousel";
import { withStyles } from '@material-ui/core/styles';
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import SimpleImageSlider from "react-simple-image-slider";
import {Button, Comment, Header, Icon, Table as reactTable} from "semantic-ui-react";
import * as ReactBootStrap from "react-bootstrap";
import Constants from "../Constants";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from "@material-ui/core/TableCell";
// import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableRowColumn from '@material-ui/core/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import InitModal from "../InitModal";

const monthView = 'dayGridMonth';
const weekView = 'timeGridWeek';
const dayView = 'timeGridDay';
const pic1Ratio = 1.773;

const useStyles = theme => ({
    clickableIcon: {
        // color: 'green',
        '&:hover': {
            color: 'royalblue',
        }
    }
});

/*const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '16px' // AllStyles.fontTable
    },
    table: {
        fontSize: '16px'
    },
}));*/

const tableFont = {
    table: {
        fontSize: '16px'
    }
};

function SplitLines(value) {

    let resultString = "";
    if (value) {
        const chapters = value.split("\n");

        console.log("SPLIT: " + resultString);
        return (
            <>
                {chapters.map((chapter, i) => (
                    // console.log("SPLIT: " + {chapter});
                    // <>{chapter}</>
                    <ListItem key={i}>{chapter}</ListItem>
                ))}
            </>
        );
    }
    else {
        return null;
    }
}

let imageFolder = "/main";
let imageHolder = [];
let imageAll = [1, 3, 4, 5, 6, 7, 8, 9, 10];

/*const createCarouselItemImage = (index, options = {}) => (
    <div key={index}>
        <img src={`/assets/${index}.jpeg`} />
        <p className="legend">Legend {index}</p>
    </div>
);*/

let createImages = (index, options = {}) => (
    <Carousel.Item>
    <img className="d-block w-100" src={`${imageFolder}/main${index}.jpg`} style={{width: '70%', height: '70%', margin: '0 auto'}} />
    </Carousel.Item>
);

let createImagesBack = (index, options = {}) => (
    <div key={index} >
        <img src={`${imageFolder}/main${index}.jpg`} style={{width: '70%', height: '70%', margin: '0 auto'}} />
        <p className="legend">Legend {index}</p>
    </div>
);

let announceListApi = '/api/announce/list';
let DateWidth = 170;
let timeWidth = 130;
let baseHeight = 200;
let contentsWIdth = 500;
let compactCount = 3;
let paperElev = 3;
let basicAlign = 'center';
let leftAlign = 'left';
const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);
const headerRow = [
    // { id: 'date', label: '날짜', minWidth: DateWidth, align: 'right' },
    // { id: 'startTime', label: '교육시간', minWidth: timeWidth, align: 'right' },
    // { id: 'endTime', label: '종료시간', minWidth: timeWidth, align: 'right' },
    { id: 'contents', label: 'Premium One-day class (in collaboration)', minWidth: contentsWIdth, align: 'right' }
];

class Home extends Component {
// export default class Home extends Component {

    calendarRef = React.createRef();
    cultures = ['ko-KR'];
    theme1 = 'bootstrap';
    imageCount = 10;
    imageList;

    images = [
        { url: "img/pic01.jpg" },
        { url: "img/flyer.jpg" },
        { url: "img/sched-12-2021.png" },
        { url: "img/pic5.jpg" },
        { url: "img/pic1.jpg" },
        { url: "img/pic2.jpg" },
        { url: "img/pic3.jpg" },
        { url: "img/pic4.jpg" },
        { url: "img/pic00.jpg" },
        { url: "img/pamphlet.jpg" }

        // { url: "img/class4.jpg" }
        /*{ url: "img/beach.jpg" },
        { url: "img/field.jpg" },
        { url: "img/kang.jpg" },
        { url: "img/lift.jpg" },
        { url: "img/lift2.jpg" },
        { url: "img/mountain.jpg" },*/
    ];

    baseWidth = 1100;
    baseLength = 600;

    carouselWidth = '55%';
    announceWidth = '55%';

    state = {
        weekendsVisible: true,
        currentEvents: [],
        commentsList: [],
        announceListCompact: [],
        announceList: [],
        announceCount: 0,
        page: 0,
        rowsPerPage: 5,
        expandedList: false,
        currWidth: 0,
        currHeight: 0,
        // glyphicon-circle-arrow-left
        nextIcon: <span className="glyphicon glyphicon-chevron-right fa-2x" style={{color: '#FCFCFC'}}></span>,
        prevIcon: <span className="glyphicon glyphicon-chevron-left fa-2x" style={{color: '#FCFCFC'}}></span>,
        slideWidth: this.baseWidth,
        slideLength: this.baseLength,
        paperHeight: 200

        //     {
        //         id: '',
        //         fullName: '',
        //         modifiedDate: '',
        //         contents: ''
        //     }
        // ]
    }

    columnNames = ['날짜', '공지내용'];  // '이름',

    fillData = () => {
        // imageHolder = Array.from({length: this.imageCount}, (_, i) => i + 1);
        console.log('imageAll: ' + imageAll);
        this.imageList = imageAll.map(createImages);

        let announceList = [
            {
                id: 0,
                fullName: '관리자',
                modifiedDate: '2021-11-13',
                contents: '12/11(토) [영어미술놀이교실] Christmas Tree 만들기 (2:00PM ~ 2:50PM)' +
                    '\n11/13(토) Premium One-day Class:  \n1. 놀이로 배우는 Phonics X 영어뮤지컬 (1:00PM ~ 1:50PM)\n  ' +
                    '2. 그림책 X 창의유아 미술놀이활동 (2:00PM ~ 2:50PM)'

            }
/*            {
                id: 0,
                fullName: '관리자',
                modifiedDate: '2021-06-03',
                contents: '클래스 신청 공지: 6/24 [Group Lesson] 그림책 놀이교실'

            },
            {
                id: 1,
                fullName: '관리자',
                modifiedDate: '2021-04-22',
                contents: '클래스 신청 공지: 5/6 [Group Lesson] 어버이날 꽃바구니 만들기'
            },
            {
                id: 2,
                fullName: '관리자',
                modifiedDate: '2021-03-18',
                contents: '클래스 신청 공지: 4/28 [Group Lesson] ❝Phonics❞ 놀이 교실'
            }*/
        ]

        let announce = {
            id: 0,
            fullName: '관리자',
            modifiedDate: '2021-03-18',
            contents: '클래스 신청 공지: 4/28 [Group Lesson] ❝Phonics❞ 놀이 교실'
        };
        let announce2 = {
            id: 1,
            fullName: '관리자',
            modifiedDate: '2021-04-29',
            contents: '클래스 신청 공지: 5/6(목) 어버이날 꽃바구니 만들기'
        };
        if (announceList) {
            console.info("[Frontend] announce");
            this.setState({
                commentsList: announceList
            });
            // this.setState({commentsList: announce});
        }
/*        if (announce2) {
            console.info("[Frontend] announce");
            this.setState({
                commentsList: this.state.commentsList.concat(
                    [
                        {
                            id: announce2.id,
                            fullName: announce2.fullName,
                            modifiedDate: announce2.modifiedDate,
                            contents: announce2.contents
                        }
                    ]
                )
            });
            // this.setState({commentsList: announce});
        }*/

    }

    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        // console.info("[Frontend] Calendar 1 historyDepth: " + JSON.stringify(historyDepth, null, 4));
    }

    getAnnounceList = () => {
        axios.get(announceListApi) // calendar
            .then(res => {
                console.log('[Frontend] TimeTable get announceList from ' + announceListApi + ', res.data: ' + JSON.stringify(res.data));
                this.setState({
                    announceListCompact: res.data.slice(0, compactCount),
                    announceList: res.data,
                    announceCount: res.data.length
                });
                // this.setState({announceList: res.data});
                // rowData.push("d");
            })
            .catch(error => {
                console.log(error);
            });
    }

    componentDidMount() {
        this.fillData();
        this.getAll();
        this.getAnnounceList();
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let currWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let currHeight = typeof window !== "undefined" ? window.innerHeight : 0;
        this.setState({currWidth, currHeight});

        if (currWidth < this.baseWidth) {
            this.setState({
                slideWidth: currWidth-30,
                slideLength: (currWidth-30) / (this.baseWidth / this.baseLength)
            });
        }
        else {
            this.setState({
                slideWidth: this.baseWidth,
                slideLength: this.baseLength
            });
        }

        console.log("[Frontend] Home updateDimensions currWidth: " + currWidth + ", currHeight: " + currHeight);
/*        if (!isMobile()) {
            this.setState({currWidth, currHeight});
        }
        else {
            if (currWidth < currHeight) {
                this.setState({currWidth, currHeight});
            }
        }*/
    }

    componentDidUpdate() {
        console.log('[Frontend] Navbar componentDidUpdate');
        // this.setState({some: '1'});
    }

    getAll = () => {
        axios.get('/base/init') // calendar
            .then(res => {
                console.log("[Frontend] Base controller check from /base/init: " + JSON.stringify(res.data));
            })
            .catch(error => {
                console.log(error);
            });
    }

    splitLines = (value) => {
        let resultString = "";
        if (value) {
            const chapters = value.split("\n");

            for (const elem of chapters) {
                resultString += elem + "\n";
            }
            console.log("SPLIT: " + resultString);
            return resultString;
        }
    }

    setPaperHeightMax = (enabled) => {
        console.log("[Frontend] Home setPaperHeightMax enabled: " + enabled);
        this.setState({expandedList: enabled});
        // this.setState({paperHeight: 600});
    }

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            page: 0
        });

        // setRowsPerPage(+event.target.value);
        // setPage(0);
    };

    render() {
        const {nextIcon,prevIcon} = this.state;
        let successMsg = '';
        let fullName = '';

        if (this.props && this.props.location && this.props.location.state) {
            this.props.location.state.some = '';
            console.log("ALL signup: " + JSON.stringify(this.props.location.state, null, 4));
            successMsg = this.props.location.state.signupMessage;
            fullName = this.props.location.state.signupFullName;
        }

        let picWidth = this.state.currWidth / 1.5;
        let picHeight = this.state.currHeight / 2.5;

        let picWidthMobile = this.state.currWidth;
        let picHeightMobile = picWidthMobile / 1.1368;
        const { classes } = this.props;
        // <span style={{wordBreak: 'normal'}}>

        return (
            <>
            <NavLinksBar/>
            <div style={{...AllStyles.mainBody, ...AllStyles.centerText, align: 'center', marginTop: '5px'}} >

                {/*<InitModal></InitModal>*/}
                {!isMobile() ?
                <>
                안녕하세요. 2代가 함께 하는 연세 유아/아동놀이교육원에 오신 걸 환영합니다.
                <br />아동들의 무한한 가능성과 잠재력이 발휘되고 실현될 수 있는 교육원 연세 유아/아동놀이교육원이 여러분과 함께 합니다!</>
                    :
                <>
                안녕하세요. 2代가 함께 하는 <br/>연세 유아/아동놀이교육원에 오신 걸 환영합니다.
                <br />아동들의 무한한 가능성과 잠재력이 <br/> 발휘되고 실현될 수 있는 교육원 <br/> 연세 유아/아동놀이교육원이 여러분과 함께 합니다!</>
                }

                <div style={{...AllStyles.center, marginTop: '10px'}}>

{/*                    <Carousel showArrows={true} >
                    <div>
                        <img alt="" src="https://picsum.photos/640/480" width="800px" />
                        <p className="legend">Caption 1</p>
                    </div>
                    <div>
                        <img alt="" src="https://picsum.photos/800/400" />
                        <p className="legend">유아 유아 놀이</p>
                    </div>
                        {imageHolder}
                        {this.imageList}
                    </Carousel>*/}

                    <Carousel nextIcon={nextIcon} prevIcon={prevIcon} interval={null} style={{width: AllStyles.carouselWidth, height: '50%', margin: '0 auto'}}>
                        {this.imageList}
                    </Carousel>

{/*                    <SimpleImageSlider
                        width={this.state.slideWidth}
                        height={this.state.slideLength}
                        navStyle={2}
                        showNavs={true}
                        showBullets={true}
                        useGPURender={true}
                        slideDuration="0.5"
                        images={this.images}
                        style={{...AllStyles.center}}
                    />*/}
                    {/*<img src="./img/class4.jpg" height={picHeight} width={picHeight * pic1Ratio} alt="text here" />*/}

                    <br/>
                    {/*component={Paper}  AllStyles.center  */}

                    {/*                        <div onClick={() => { this.setPaperHeightMax() }}>더보기
                            <ExpandMoreIcon onClick={() => { this.setPaperHeightMax() }} className={classes.clickableIcon} />
                        </div>*/}

                    <Paper style={{ width: AllStyles.carouselWidth, marginTop: '30px', margin: '0 auto' }} elevation={paperElev} >
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {headerRow.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={leftAlign}
                                                style={{ ...AllStyles.fontTableHeader, minWidth: column.minWidth }}
                                                colSpan={2}
                                            >
                                                &nbsp;&nbsp;&nbsp;
                                                <Icon name='announcement' color={'pink'}
                                                      style={{marginRight: '20px'}} />
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!this.state.expandedList && this.state.announceList != null && this.state.announceList.length > 0 ?
                                        <>
                                        {this.state.announceListCompact.map((row) => (
                                                <TableRow key={row.id}>
                                                    {/* component="th" scope="row"  , minWidth: timeWidth*/}
                                                    <TableCell align={leftAlign} style={{ ...AllStyles.fontTable, width: '25%' }}>
                                                        {row.startDate}
                                                    </TableCell>
                                                    {/*<TableCell align={basicAlign} style={{ ...AllStyles.fontTable, minWidth: timeWidth }}>{row.startHour}</TableCell>*/}
                                                    {/*<TableCell align={basicAlign} style={{ ...AllStyles.fontTable }}>{row.endHour}</TableCell>*/}
                                                    <TableCell align={leftAlign} style={{ ...AllStyles.fontTable, width: '75%' }}>{row.contents}</TableCell>
                                                </TableRow>
                                            ))}
                                        </> : ''}

                                    {!this.state.expandedList && this.state.announceList != null &&
                                    this.state.announceList.length > compactCount ?
                                            <>
                                                <div>
                                                    <a><ExpandMoreIcon fontSize="large" onClick={() => { this.setPaperHeightMax(true) }} className={classes.clickableIcon}>
                                                        ... </ExpandMoreIcon></a>
                                                </div>
                                            </>: ''}

                                    {this.state.expandedList && this.state.announceList != null ?
                                        <>
                                            {this.state.announceList
                                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                .map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row" align={basicAlign} style={{ ...AllStyles.fontTable, minWidth: timeWidth }}>
                                                        {row.startDate}
                                                    </TableCell>
                                                    {/*<TableCell align={basicAlign} style={{ ...AllStyles.fontTable }}>{row.startHour}</TableCell>*/}
                                                    {/*<TableCell align={basicAlign} style={{ ...AllStyles.fontTable }}>{row.endHour}</TableCell>*/}
                                                    <TableCell align={leftAlign} style={{ ...AllStyles.fontTable }}>{row.contents}</TableCell>
                                                </TableRow>
                                            ))}
                                            <a><ExpandLessIcon fontSize="large" onClick={() => { this.setPaperHeightMax(false) }} className={classes.clickableIcon}> ... </ExpandLessIcon></a>
                                        </> : ''}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {this.state.expandedList && this.state.announceList != null ?
                        <>
                        <TablePagination
                            component="div"
                            count={this.state.announceCount}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[]}
                            // onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                        </> : ''}

                    </Paper>

{/*                    <Paper style={{width: this.carouselWidth, margin: '0 auto'}} elevation={3} >

                    <TableContainer style={{width: this.announceWidth, margin: '0 auto'}}>

                        <Table aria-label="simple table" >
                            <TableBody style={{...AllStyles.fontTable}}>
                                {this.state.commentsList != null && this.state.commentsList.length > 0 ?
                                    this.state.commentsList.map(elem => {
                                        return (
                                            <TableRow key={elem.id} style={{height: '10'}} >
                                                <TableCell style={{...AllStyles.fontTable, borderBottom: 'none'}}>
                                                    <Icon name='announcement' color={'pink'}
                                                          style={{marginRight: '20px'}} />
                                                        {SplitLines(elem.contents)}
                                                </TableCell>

                                            </TableRow>
                                        );
                                    }): null}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    </Paper>*/}

                    {/*<Icon name='announcement' />*/}

{/*                    <Table fixed size='large' textAlign={'center'} verticalAlign={'middle'} style={{width: this.state.slideWidth}} >
                        <Table.Header>
                            <Table.Row>
                                {this.columnNames.map((day) => (
                                    <Table.HeaderCell
                                        style={{backgroundColor: '#ffffcc'}}>{day}</Table.HeaderCell>))
                                }

                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.commentsList != null && this.state.commentsList.length > 0 ?
                                this.state.commentsList.map(elem => {
                                return (
                                    <Table.Row key={elem.id}>
                                        <Table.Cell>{elem.fullName}</Table.Cell>
                                        <Table.Cell>{elem.modifiedDate}</Table.Cell>
                                        <Table.Cell>{elem.contents}</Table.Cell>
                                    </Table.Row>
                                );
                            }): null}
                        </Table.Body>
                    </Table>*/}


                </div>
            </div>
            </>
        );
    }
}

export default withStyles(useStyles)(Home)
