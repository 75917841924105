import React, { Component } from 'react';
import { NavLinksBar } from '../Navbar';
import ReactRoundedImage from "react-rounded-image";

import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {isMobile} from '../styles/AllStyles';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';


import '../Custom.css';
import {AllStyles} from "../styles/AllStyles";
import axios from "axios";
import moment from 'moment';

const monthView = 'dayGridMonth';
const weekView = 'timeGridWeek';
const dayView = 'timeGridDay';

const useStyles = theme => ({
    paper: {
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column'
        // alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
});

class Teachers extends Component {

    calendarRef = React.createRef();

    cultures = ['ko-KR'];
    theme1 = 'bootstrap';

    state = {
        weekendsVisible: true,
        currentEvents: []
    }

    bigColumnWidth = 9;

    constructor(props) {
        super(props);

        this.getAll();
        // console.info("[Frontend] Calendar 1 historyDepth: " + JSON.stringify(historyDepth, null, 4));
    }

    componentDidMount() {

    }

    getAll = () => {
        if (isMobile()) {
            this.bigColumnWidth = 12;
        }
        else {
            this.bigColumnWidth = 9;
        }
    }

    render() {
        const {classes} = this.props;

        // left corner  &#12300;  &#12301;

        return (
            <>
            <NavLinksBar/>
            <div className='demo-app' style={{...AllStyles.mainBody, marginLeft: '30px', marginRight: '30px', marginTop: '20px'}}>

                <Grid container spacing={3}>
                    <div style={{...AllStyles.leftColumn, marginLeft: '40px', marginTop: '20px' }}>
                        <Grid item xs={2}>

                                <ReactRoundedImage
                                    image={process.env.PUBLIC_URL + '/img/yooj.jpg'}
                                    // roundedColor="#66A5CC"
                                    imageWidth="160"
                                    imageHeight="160"
                                    roundedSize="3"
                                />

                        </Grid>
                    </div>

                    <Grid item xs={this.bigColumnWidth}>
                        {/*<Paper className={classes.paper} >*/}
                            <div style={{...AllStyles.ceoTitle, paddingTop: '0px', fontFamily: 'Yonsei' }}>&#9883; 원장: 오 유 진</div><br/>

                            {/*<div id={"intro"} style={AllStyles.accented}>*/}
                            <div style={{fontFamily: 'osung'}}>
                                &#10000; 이화여자외국어고등학교 졸업 <br/>
                                &#10000; 연세대학교 아동가족학과 졸업 <a>(교직 이수)</a> <br/>
                                {/*<Text style={{fontSize: '20px'}}>*/}
                                &#10000; 연세대학교 일반대학원 아동가족학과 졸업 <a><br/>&nbsp;&nbsp;&nbsp;(유아교육 & 보육)</a> <br/><br/>
                            </div>

                            {!isMobile() ?
                            <div style={{fontFamily: 'osung'}}>
                                &#9784; 경력 (Since 2009~)<br/>
                                &#10004; 現 해커스 평생교육원 교수<br/>
                                &#10004; 現 YBM NET 평생교육원 교수<br/>
                                &#10004; 現 배론 평생교육원 교수<br/>
                                {/*&#10004; 現 서초여성가족플라자 방배센터 강사 (유아영어놀이, Phonics) <br/>*/}
                                &#10004; 상지영서대학교 유아교육과 강사<br/>
                                &#10004; 상지영서대학교 평생교육원 강사<br/>
                                &#10004; 그리스도대학교 보육교사교육원 강사<br/>
                                &#10004; 강원도 보육교직원 보수교육 (보육교사과정, 보육교사 1급 승급과정,보육교사 2급 승급과정)<br/>

                                &#10004; 휴넷 평생교육원 교수<br/>
                                &#10004; 세움 사회복지평생교육원 교수<br/>
                                &#10004; 롯데백화점 본점 문화센터 강사 (영어회화)<br/>
                                &#10004; 신세계백화점 아카데미 본점 강사 (부모교육)<br/>
                                &#10004; 캐나다 Day Care 과정, 호주 Aged Care 과정 (국비지원 해외취업 연수)<br/>
                                &#10004; 사립초등학교 영어교과 전담 강사,
                                영어 방과후 교실<br/><br/>
                                &#9885; 자격증<br/>
                                국제어린이영어교사 (YL-TESOL)<br/>
                                영어독서지도사 (Certificate of Reading Specialist)<br/>
                                어린이집 원장<br/>
                                보육교사 1급<br/>
                                유치원정교사 2급<br/>
                                평생교육사2급<br/><br/>
                            </div> :

                                <div style={{fontFamily: 'osung'}}>
                                &#9784; 경력 (Since 2009~)<br/>
                                &#10004; 現 해커스 평생교육원 교수<br/>
                                &#10004; 現 YBM NET 평생교육원 교수<br/>
                                &#10004; 現 배론 평생교육원 교수<br/>
                                {/*&#10004; 現 서초여성가족플라자 방배센터 강사 (유아영어놀이, Phonics) <br/>*/}
                                &#10004; 상지영서대학교 유아교육과 강사<br/>
                                &#10004; 상지영서대학교 평생교육원 강사<br/>
                                &#10004; 그리스도대학교 보육교사교육원 강사<br/>
                                &#10004; 강원도 보육교직원 보수교육 <br/>(보육교사과정, 보육교사 1급 및 2급 승급과정)<br/>

                                &#10004; 휴넷 평생교육원 교수<br/>
                                &#10004; 세움 사회복지평생교육원 교수<br/>
                                &#10004; 롯데백화점 본점 문화센터 강사 (영어회화)<br/>
                                &#10004; 신세계백화점 아카데미 본점 강사 (부모교육)<br/>
                                &#10004; 캐나다 Day Care 과정, <br/>
                                &#10004; 호주 Aged Care 과정 (국비지원 해외취업 연수)<br/>
                                &#10004; 사립초등학교 영어교과 전담 강사<br/>
                                &#10004; 영어 방과후 교실<br/><br/>
                                &#9885; 자격증<br/>
                                국제어린이영어교사 (YL-TESOL)<br/>
                                영어독서지도사 (Certificate of Reading Specialist)<br/>
                                어린이집 원장<br/>
                                보육교사 1급<br/>
                                유치원정교사 2급<br/>
                                평생교육사2급<br/><br/>
                                </div> }
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <div style={{...AllStyles.leftColumn, marginLeft: '40px', marginTop: '10px' }}>
                        <Grid item xs={2}>

                            <ReactRoundedImage
                                image={process.env.PUBLIC_URL + '/img/minjoo.jpg'}
                                // roundedColor="#66A5CC"
                                imageWidth="160"
                                imageHeight="160"
                                roundedSize="3"
                            />

                        </Grid>
                    </div>
                    <Grid item xs={this.bigColumnWidth}>
                            <div style={{...AllStyles.ceoTitle, paddingTop: '0px', fontFamily: 'Yonsei' }}>&#9883; 부원장: 임 민 주</div><br/>

                            {/*<div id={"intro"} style={AllStyles.accented}>*/}

                            {!isMobile() ?
                            <div style={{fontFamily: 'osung'}}>
                                &#10000; 가천대학교 성악과 졸업<br/><br/>
                                &#9784; 국립오페라단 출연작품<br/>

                                <Grid container spacing={3}>
                                    <Grid item xs={this.bigColumnWidth}>
                                        &#10004; 2020년: &#12300;국립합창단 기획 광복절기념 합창 축제&#12301;, &#12300;라트라비아타&#12301;,
                                        &#12300;마농&#12301;, &#12300;빨간바지&#12301;<br/>
                                        &#10004; 2019년: &#12300;대한민국 오페라 페스티벌&#12301;, &#12300;클래식 음악 축제&#12301;, &#12300;팔리아치&#12301;,
                                        &#12300;파우스트&#12301;,<br/> &#12300;위대한 개츠비&#12301;, &#12300;투란도트&#12301;, &#12300;동백꽃아가씨&#12301;, &#12300;토스카&#12301;,
                                        &#12300;마술피리&#12301;<br/>
                                        &#10004; 2018년: &#12300;로엔그린&#12301;, &#12300;세아이운형문화재단 음악회&#12301;, &#12300;동백꽃아가씨&#12301;, &#12300;라보엠&#12301;,<br/>
                                        &#12300;마농&#12301;, &#12300;유쾌한 미망인&#12301;, &#12300;사랑의 묘약&#12301;, &#12300;피가로의 결혼&#12301;, &#12300;오르페오와 에우리디체&#12301;<br/>
                                        &#10004; 2017년: &#12300;마술피리, &#12300;팔리아치&외투&#12301;, &#12300;오를란도 핀토 파쵸&#12301;, &#12300;리골레또&#12301;,<br/>
                                        &#12300;진주조개잡이&#12301;, &#12300;라트라비아타&#12301; <br/>
                                        &#10004; 2016년: &#12300;나비부인&#12301;,&#12300;국립오페라 갈라&#12301; <br/>
                                        &#10004; 2015년: &#12300;라트라비아타&#12301;,&#12300;천생연분&#12301;(터키, 홍콩) <br/>
                                    </Grid>

                                    {/*<Grid item xs={9}>
                                        &#12300;국립합창단 기획 광복절기념 합창 축제&#12301;, &#12300;라트라비아타&#12301;,
                                        &#12300;마농&#12301;, &#12300;빨간바지&#12301;<br/>

                                        &#12300;대한민국 오페라 페스티벌&#12301;, &#12300;클래식 음악 축제&#12301;, &#12300;팔리아치&#12301;,
                                        &#12300;파우스트&#12301;, &#12300;위대한 개츠비&#12301;, &#12300;투란도트&#12301;, &#12300;동백꽃아가씨&#12301;, &#12300;토스카&#12301;,
                                        &#12300;마술피리&#12301;<br/>

                                        &#12300;로엔그린&#12301;, &#12300;세아이운형문화재단 음악회&#12301;, &#12300;동백꽃아가씨&#12301;, &#12300;라보엠&#12301;,
                                        &#12300;마농&#12301;, &#12300;유쾌한 미망인&#12301;, &#12300;사랑의 묘약&#12301;, &#12300;피가로의 결혼&#12301;, &#12300;오르페오와 에우리디체&#12301;<br/>

                                        &#12300;마술피리, &#12300;팔리아치&외투&#12301;, &#12300;오를란도 핀토 파쵸&#12301;, &#12300;리골레또&#12301;,
                                        &#12300;진주조개잡이&#12301;, &#12300;라트라비아타&#12301; <br/>

                                        &#12300;나비부인&#12301;,&#12300;국립오페라 갈라&#12301; <br/>

                                        &#12300;라트라비아타&#12301;,&#12300;천생연분&#12301;(터키, 홍콩) <br/>

                                    </Grid>*/}

                                </Grid>
                            </div> :
                                <div style={{fontFamily: 'osung'}}>
                                    &#10000; 가천대학교 성악과 졸업<br/><br/>
                                    &#9784; 국립오페라단 출연작품<br/>

                                    <Grid container spacing={3}>
                                        <Grid item xs={this.bigColumnWidth}>
                                            &#10004; 2020년: &#12300;라트라비아타&#12301;,
                                            &#12300;마농&#12301;, &#12300;빨간바지&#12301;,<br/> &#12300;국립합창단 기획 광복절기념 합창 축제&#12301; <br/>
                                            &#10004; 2019년: &#12300;마술피리&#12301;, &#12300;토스카&#12301;, <br/>&#12300;클래식 음악 축제&#12301;, &#12300;팔리아치&#12301;,
                                            &#12300;파우스트&#12301;,<br/> &#12300;위대한 개츠비&#12301;, &#12300;투란도트&#12301;, &#12300;동백꽃아가씨&#12301;,
                                            <br/>&#12300;대한민국 오페라 페스티벌&#12301; <br/>
                                            &#10004; 2018년: &#12300;로엔그린&#12301;, &#12300;동백꽃아가씨&#12301;, &#12300;라보엠&#12301;,<br/>
                                            &#12300;마농&#12301;, &#12300;유쾌한 미망인&#12301;, &#12300;사랑의 묘약&#12301;,<br/> &#12300;피가로의 결혼&#12301;,
                                            &#12300;오르페오와 에우리디체&#12301;,<br/>
                                            &#12300;세아이운형문화재단 음악회&#12301;<br/>
                                            &#10004; 2017년: &#12300;마술피리, &#12300;팔리아치&외투&#12301;,
                                            <br/>&#12300;오를란도 핀토 파쵸&#12301;, &#12300;리골레또&#12301;,<br/>
                                            &#12300;진주조개잡이&#12301;, &#12300;라트라비아타&#12301; <br/>
                                            &#10004; 2016년: &#12300;나비부인&#12301;, &#12300;국립오페라 갈라&#12301; <br/>
                                            &#10004; 2015년: &#12300;라트라비아타&#12301;, &#12300;천생연분&#12301; (터키, 홍콩) <br/>
                                        </Grid>
                                    </Grid>
                                </div> }
                    </Grid>
                </Grid><br/><br/>

                {/*<Grid container spacing={3}>
                    <div style={{...AllStyles.leftColumn, marginLeft: '40px', marginTop: '10px' }}>
                        <Grid item xs={2}>

                            <ReactRoundedImage
                                image={process.env.PUBLIC_URL + '/img/ohjehun.jpg'}
                                imageWidth="160"
                                imageHeight="180"
                                roundedSize="3"
                            />

                        </Grid>
                    </div>
                    <Grid item xs={this.bigColumnWidth}>
                        <div style={{...AllStyles.ceoTitle, paddingTop: '0px', fontFamily: 'Yonsei' }}>&#9883; 전문강사: 오 제 훈 </div><br/>

                        <div id={"intro"} style={AllStyles.accented}>
                        <div style={{fontFamily: 'osung'}}>
                            &#10000; 現 한국외국어대학교 교육대학원 석사과정<br/>
                            &#10000; 한국외국어대학교 영문학과 졸업<br/><br/>
                            &#9784; 경력<br/>
                            &#10004; 사립초등학교 영어과, 방과후교실, 영어캠프<br/>
                            &#10004; SDL Korea 한영번역팀 근무<br/>
                            &#10004; 서울버들초등학교 방과후 영어교실 강사<br/>
                            &#10004; 창동중학교 CNN반 강사<br/>
                            &#10004; 전북현대모터스축구단 영문사이트 번역<br/>
                            &#10004; AFC 경기장표준규정집 경기용품규정집 번역<br/>
                            &#10004; 2009 광주시 어린이 영어캠프 강사<br/>
                            &#10004; Michigan University 교환학생<br/><br/>
                        </div>
                        <div style={{fontFamily: 'osung'}}>
                            &#9885; 자격증<br/>
                            TESOL Certificate<br/>
                            TEE (Teaching English in English)<br/>
                        </div>
                    </Grid>
                </Grid><br/><br/>*/}

                <Grid container spacing={3}>
                    <div style={{...AllStyles.leftColumn, marginLeft: '40px', marginTop: '10px' }}>
                        <Grid item xs={2}>

                            <ReactRoundedImage
                                image={process.env.PUBLIC_URL + '/img/wonsuk2.jpg'}
                                imageWidth="160"
                                imageHeight="180"
                                roundedSize="3"
                            />

                        </Grid>
                    </div>
                    <Grid item xs={this.bigColumnWidth}>
                        <div style={{...AllStyles.ceoTitle, paddingTop: '0px', fontFamily: 'Yonsei' }}>&#9883; 전문강사: 오 원 석 </div><br/>

                        {/*<div id={"intro"} style={AllStyles.accented}>*/}
                            <div style={{fontFamily: 'osung'}}>
                                &#10000; 現 건국대학교 일반대학원 부동산학과 박사과정<br/>
                                &#10000; 건국대학교 일반대학원 부동산학과 석사 졸업<br/>
                                &#10000; 한림대학교 금융정보통계학과(수학과) 졸업<br/>
{/*                                <Grid container spacing={3}>
                                    <Grid item xs={this.bigColumnWidth}>
                                        &#10004; 現 건국대학교 부동산학과 일반대학원 박사과정 재학중<br/>
                                        &#10004; 건국대학교 부동산학과 일반대학원 석사 졸업<br/>
                                        &#10004; 한업림대학교 금융정보통계학과(수학과)<br/>
                                    </Grid>
                                </Grid>*/}
                            </div>
                    </Grid>
                </Grid>

            </div>
            </>
        )
    }
}

export default withStyles(useStyles)(Teachers);

/*<Grid item xs={1.5}>
    &#10004; 2020년:<br/>
    &#10004; 2019년:<br/>
    &#10004; 2018년:<br/>
    &#10004; 2017년:<br/>
    &#10004; 2016년:<br/>
    &#10004; 2015년:<br/>
</Grid>

<Grid item xs={9}>
    &#12300;국립합창단 기획 광복절기념 합창 축제&#12301;, &#12300;라트라비아타&#12301;,
    &#12300;마농&#12301;, &#12300;빨간바지&#12301;<br/>
    &#12300;대한민국 오페라 페스티벌&#12301;, &#12300;클래식 음악 축제&#12301;, &#12300;팔리아치&#12301;,
    &#12300;파우스트&#12301;, &#12300;위대한 개츠비&#12301;, &#12300;투란도트&#12301;, &#12300;동백꽃아가씨&#12301;, &#12300;토스카&#12301;,
    &#12300;마술피리&#12301;<br/>
    &#12300;로엔그린&#12301;, &#12300;세아이운형문화재단 음악회&#12301;, &#12300;동백꽃아가씨&#12301;, &#12300;라보엠&#12301;,
    &#12300;마농&#12301;, &#12300;유쾌한 미망인&#12301;, &#12300;사랑의 묘약&#12301;, &#12300;피가로의 결혼&#12301;, &#12300;오르페오와 에우리디체&#12301;<br/>
    &#12300;마술피리, &#12300;팔리아치&외투&#12301;, &#12300;오를란도 핀토 파쵸&#12301;, &#12300;리골레또&#12301;,
    &#12300;진주조개잡이&#12301;, &#12300;라트라비아타&#12301; <br/>
    &#12300;나비부인&#12301;,&#12300;국립오페라 갈라&#12301; <br/>
    &#12300;라트라비아타&#12301;,&#12300;천생연분&#12301;(터키, 홍콩) <br/>

</Grid>*/

// &#10004; 2015년: &#12300;라트라비아타&#12301;,&#12300;천생연분&#12301;(터키, 홍콩) <br/>

// &#10004; 2016년: &#12300;나비부인&#12301;,&#12300;국립오페라 갈라&#12301; <br/>

// &#10004; 2017년: &#12300;마술피리, &#12300;팔리아치&외투&#12301;, &#12300;오를란도 핀토 파쵸&#12301;, &#12300;리골레또&#12301;,
// &#12300;진주조개잡이&#12301;, &#12300;라트라비아타&#12301; <br/>

// &#10004; 2018년: &#12300;로엔그린&#12301;, &#12300;세아이운형문화재단 음악회&#12301;, &#12300;동백꽃아가씨&#12301;, &#12300;라보엠&#12301;,
// &#12300;마농&#12301;, &#12300;유쾌한 미망인&#12301;, &#12300;사랑의 묘약&#12301;, &#12300;피가로의 결혼&#12301;, &#12300;오르페오와 에우리디체&#12301;<br/>

// &#10004;2019년: &#12300;대한민국 오페라 페스티벌&#12301;, &#12300;클래식 음악 축제&#12301;, &#12300;팔리아치&#12301;,
// &#12300;파우스트&#12301;, &#12300;위대한 개츠비&#12301;, &#12300;투란도트&#12301;, &#12300;동백꽃아가씨&#12301;, &#12300;토스카&#12301;,
// &#12300;마술피리&#12301;<br/>

// &#10004;2020년: &#12300;국립합창단 기획 광복절기념 합창 축제&#12301;, &#12300;라트라비아타&#12301;,
// &#12300;마농&#12301;, &#12300;빨간바지&#12301;<br/>
