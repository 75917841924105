// import 'bootstrap/dist/css/bootstrap.min.css'
// import App from './AppMall';
import './index.css';
import './App.css';
import React, { useState }  from 'react';
import { NavLinksBar } from './Navbar.js';
import {Route, Switch} from "react-router-dom";

// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import AuthenticationService from "./AuthenticationService";
import ReactDOM from "react-dom";

// import React from 'react';
// import ReactDOM from 'react-dom';

/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
  // document.getElementById('navbar')
);*/

// ReactDOM.render(<App />, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

let host = window.location.host;
let protocol = window.location.protocol;
let parts = host.split(".");
let subdomain = "";
console.log("[index.js] init prot: " + protocol + ", init parts: " + parts + ", length: " + parts.length);

// If we get more than 3 parts, then we have a subdomain
// INFO: This could be 4, if you have a co.uk TLD or something like that.
if (parts.toString().startsWith('mall')) {
    console.log("[index.js] init prot mall: " + parts);
    let appMail = import('./AppMall');
    // import('./AppMall');
}
else {
    let appMail = import('./AppNew');
}

/*if (parts.length >= 3) {
    subdomain = parts[0];
    // Remove the subdomain from the parts list
    parts.splice(0, 1);
    // Set the location to the new url
    // window.location = protocol + "//" + parts.join(".") + "/" + subdomain;
}*/

serviceWorker.unregister();
