import { useState, useEffect } from 'react';
import moment from 'moment';

function getWindowDimensions() {
    const { innerWidth: currWidth, innerHeight: currHeight } = window;
    return {
        currWidth,
        currHeight
    };
}

const mapLabelsCommon = [
    'airport-label',
    'country-label',
    'kr-local',
    'kr-metro-expy',
    'kr-metropolitan',
    'marine-label',
    'metro-rail',
    'mountain-peak-label',
    'natural-label',
    'natural-point-label',
    'natural-line-label',
    'place-label',
    'poi-label',
    'rail',
    'rail-metro',
    'rail-station-label',
    'road',
    'road-label',
    'state-label',
    'settlement-label',
    'settlement-subdivision-label',
    'transit-stop-label',
    'water-label',
    'water-line-label',
    'water-point-label',
    'waterway-label',
];

let vworldKey = 'A3296F2A-5122-396B-B69E-244A968EF20D';

let mapStylesCommon = {

    mapbox_base : {
        "version": 8,
        "sources": {
            "mapbox_base": {
                "type": "raster",
                // point to our third-party tiles. Note that some examples
                // show a "url" property. This only applies to tilesets with
                // corresponding TileJSON (such as mapbox tiles).
                "tiles": [
                    'https://{s}.tiles.mapbox.com/v3/pk.eyJ1IjoibWNzb25pYzciLCJhIjoiY2tienpqNDhzMWVjbDJzbGc0ZHp1ZzhxciJ9.cQ5RwSwMgo_20N5XwvxCOg/{z}/{x}/{y}.png'
                ],
                "tileSize": 256
            }
        },
        "layers": [{
            "id": "mapbox_base",
            "type": "raster",
            "source": "mapbox_base",
            "minzoom": 1,
            "maxzoom": 19
        }]
    },

    vworld_base : {
        "version": 8,
        "sources": {
            "vworld_base": {
                "type": "raster",
                // point to our third-party tiles. Note that some examples
                // show a "url" property. This only applies to tilesets with
                // corresponding TileJSON (such as mapbox tiles).
                "tiles": [
                    'https://api.vworld.kr/req/wmts/1.0.0/' + vworldKey + '/Base/{z}/{y}/{x}.png'
                    // 'https://xdworld.vworld.kr/2d/Base/201812/{z}/{x}/{y}.png'
                ],
                "tileSize": 256
            }
        },
        "layers": [{
            "id": "vworld_base",
            "type": "raster",
            "source": "vworld_base",
            "minzoom": 1,
            "maxzoom": 19
        }]
    },
    vworld_white : {
        "version": 8,
        "sources": {
            "vworld_white": {
                "type": "raster",
                // point to our third-party tiles. Note that some examples
                // show a "url" property. This only applies to tilesets with
                // corresponding TileJSON (such as mapbox tiles).
                "tiles": [
                    'http://xdworld.vworld.kr:8080/2d/gray/201612/{z}/{x}/{y}.png'
                ],
                "tileSize": 256
            }
        },
        "layers": [{
            "id": "vworld_white",
            "type": "raster",
            "source": "vworld_white",
            "minzoom": 1,
            "maxzoom": 19
        }]
    },
    vworld_black : {
        "version": 8,
        "sources": {
            "vworld_black": {
                "type": "raster",
                // point to our third-party tiles. Note that some examples
                // show a "url" property. This only applies to tilesets with
                // corresponding TileJSON (such as mapbox tiles).
                "tiles": [
                    'http://xdworld.vworld.kr:8080/2d/midnight/201612/{z}/{x}/{y}.png'
                ],
                "tileSize": 256
            }
        },
        "layers": [{
            "id": "vworld_black",
            "type": "raster",
            "source": "vworld_black",
            "minzoom": 1,
            "maxzoom": 19
        }]
    }
};

const schedHours = {
    minTime: 10,
    maxTime: 18,
    midDay: 12,
    am: ' AM',
    pm: ' PM'
};

function getHourRange24(hours) {
    let hourText;
    hourText = hours + ' ' + ++hours;
    // console.log('[Common] getHourRange24: ' + hourText);
    return hourText.split(" ");
}

function getHourRange(hours) {
    let hourText;
    hourText = convertReadable(hours) + ' ~ ' + convertReadable(hours+1);
    return hourText;
}

function getHourRangeEnd(startHour, endHour) {
    let hourText;
    hourText = convertReadable(startHour) + ' ~ ' + convertReadable(endHour);
    return hourText;
}

function convertToHours(hoursReadable) {
    let res = hoursReadable.split(' ~ ');
    return res;
}

// bTo24Hours true: 2 PM -> 14
function convertReadable(hours, bTo24Hours) {
    if (bTo24Hours) {
        return moment(hours, ["h A"]).format("HH");
    }
    else {
        return moment(hours, ["HH"]).format("hh A");
    }

/*    let hourText;
    if (hours >= schedHours.midDay && hours < schedHours.midDay+1) {
        hourText = hours + schedHours.pm;
    }
    else if (hours <= schedHours.midDay) {
        hourText = hours + schedHours.am;
    }
    else {
        hourText = hours-12 + schedHours.pm;
    }
    return hourText;*/
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export { mapStylesCommon, mapLabelsCommon, getHourRange, getHourRangeEnd, getHourRange24, convertReadable, convertToHours, schedHours };