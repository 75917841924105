import React, { Component } from 'react';
// import useWindowDimensions from '../menu/Common';

const mobileWidth = 330;

let currWidth = 800;
let currHeight = 600;

function isMobile() {
    let userAgent = window.navigator.userAgent;
    console.log("UserAgent string: " + userAgent + ", windows orientation: " + JSON.stringify(window.orientation, null, 4));
    let isMobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    console.log("AllStyles Orientation change isMobile?: " + isMobile);
    // getWidth();
    return isMobile;
    // return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

function setWidth(newWidth) {
    currWidth = newWidth;
    console.log('AllStyles setWidth: ' + newWidth);
}

function setHeight(newHeight) {
    currHeight = newHeight;
    console.log('AllStyles setHeight: ' + newHeight);
}

function getCurrWidth() {
    return currWidth;
}

function getCurrHeight() {
    return currHeight;
}

function getWidth() {
    // let angle = window.orientationType;
    let height = window.screen.height;
    let width = window.screen.width;  // window.screen.width;  innerWidth
    let resultWidth;

    if (height > width) {
        resultWidth = width;
    }
    else {
        resultWidth = height;
    }
    // JSON.stringify(angle, null, 4)
    console.log("AllStyles Orientation change Mobile angle: " + ", " +
        "height: " + height + ", width: " + width + ", real width: " + resultWidth);

    return width;
}

function getAddrWidth() {
    return 650;
}

function setColorRange(allData, geoKey) {
    const numColors = 4;
    let mapInterval = {
        // lev1: 0,
        // lev2: 0,
        // lev3: 0,
        // lev4: 0
    };

    let maxVal = Math.max.apply(
        Math, allData.map(function(obj) {
            return obj.properties[geoKey]; }));
    let minVal = Math.min.apply(
        Math, allData.map(function(obj) {
            return obj.properties[geoKey]; }));
    let interval = (maxVal - minVal) / numColors;

    console.log("[AllStyles] setColorRange geoKey: max: " + maxVal + ", min: " + minVal +
        ", interval: " + interval);

    for (let i=0; i<numColors; i++) {
        mapInterval[i] = minVal + (i+1) * interval;
    }
    console.log("setColorRange: " + JSON.stringify(mapInterval));
    return mapInterval;
}

// const AllStylesComp = () => {
//
//     const { currHeight, currWidth } = useWindowDimensions();

    const AllStyles = {
        basicHeight: {height: 42},
        input: {width: 260},
        inputLong: {width: 320},
        inputLongAll: {width: 600},
        marginButton: {marginLeft: 50},
        margin50: {
            marginTop: 50,
            marginBottom: 50
        },
        divNext: {overflow: 'hidden'},
        flushBottom: {
            position: 'absolute',
            bottom: 0,
            left: 0
        },
        // width: "26em"
        inputHashtags: {
            width:
                isMobile() ?
                    "22em" : "26em",
            fontSize: "14px"
        },
        inputGeom: {
            width:
                isMobile() ?
                    '300px' : '415px',
            // "16em" : "29em",
            fontSize: "14px"
        },
        inputDesc: {
            marginRight: '15px',
            width:
                isMobile() ?
                    '220px' : '350px',
            // "16em" : "29em",
            fontSize: "14px"
        },
        evtDesc: {
            marginLeft: '20px',
            // marginRight: '15px',
            marginTop: '20px',
            marginBottom: '30px',
            width:
                isMobile() ?
                    '320px' : '450px',
            // "16em" : "29em",
            fontSize: "14px"
        },

        button: {
            width:
                isMobile() ?
                    "150px" : "180px",
            fontSize:
                isMobile() ?
                    '12px' : '14px'
        },
        bigButton: {
            width: "20em"
        },
        calendarWidth: 1500,
        calendarHeight: 700,
        centerSimple: {
            margin: '0 auto'
        },
        center: {
            // margin: 'auto',
            // width: '50%',
            display: 'block',
            margin: '0 auto',
            padding: '0px',
            border: '0px',
            // overflow: 'hidden',
            position: 'relative',
            flex: '1'

            // width: '50%',
            // margin: '0 auto',
            // textAlign: 'center'
            // align: 'center'
        },
        centerAll:
            isMobile() ?
            {
                marginLeft: '0%',
                marginRight: '0%'
            }:
            {
                marginLeft: '15%',
                marginRight: '15%'
            },
            // margin: '0 10%'
            // align: 'center'

        centerText: {
            textAlign: 'center'
        },
        navBrand: {
            width:
                isMobile() ?
                    '35': '80',
            fontSize:
                isMobile() ?
                    '16px': '24px'
        },
        navMenu: {
            // width:
            //     isMobile() ?
            //         '35': '80',
            fontSize:
                isMobile() ?
                    '14px': '20px'
        },
        bottomAlign: {
            position: 'fixed',
            width: '100%',
            bottom: '130px'
            // border: 3px solid #8AC007;
        },

        margin30: {
            margin: "30px"
        },
        marginTopBotAdmin: {
            marginTop: "50px",
            marginBottom: "50px"
        },
        marginLeft20: {
            marginLeft: "20px"
        },
        marginLeft30: {
            marginLeft: "30px"
        },
        marginNavLink: {
            marginLeft: "20px",
            // color: "rgba(255, 255, 255, 1)"
        },
        marginFirstItem: {
            marginLeft: "20px"
        },
        noWrap: {
            whiteSpace: 'nowrap'
        },

        menuHeight: {
            height: '80'
        },
        mapContainer: {
            position: 'relative',
            top: '0',
            right: '0',
            left: '0',
            bottom: '0',
            width: '100%',
            height: '100%'
        },
        mapWrapperFull: {
            position: 'absolute',
            width: '100%'
            // height: '90%'
            // height: '600px',
            /*height: 50%;*/
        },

        right: {
            textAlign: 'right'
        },
        createInput: {
            width: '19em'
        },
        createButton: {
            width: '10em'
        },
        card: {
            width:
                isMobile() ?
                    '330px' : '800px',
            // backgroundColor: '#eaf1f8'
        },
        popupStyle: {
            width: '50px'
        },
        postCodeStyle: {
            // display: 'flex',
            display: "block",  // block
            position: "absolute",  //relative fixed absolute
            // position: 'relative',

            // top: "50%",
            // width: "400px",
            // height: "500px",
            // padding: "7px",

            top: -getCurrHeight()/3,
            left: getCurrWidth()/2 - getAddrWidth(),

            width: getAddrWidth(),
            // width: currWidth,
            // width: getAddrWidth(),
            height: 470,
            // transparent: true,
            background: 'rgba(0, 0, 0, 0.2)',
            // backgroundColor: 'white',
            // backgroundColor: 'rgba(0, 0, 0, 0.5)',
            // alignContent: 'center',
            // justifyContent: 'center',
            // alignItems: 'center',
            // align: 'center',
            // justify: 'center',
            zIndex: "100",
            border: "1px solid #000000",
            overflow: "hidden"
        },

        signup:
            isMobile() ?
            // width:
            //     isMobile()?
            //         '330px': '600px',
            {
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100%',
                minWidth: '300px',
                minHeight: '600px',
                padding: '20px 40px',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                align: 'center',
                justify: 'center'
            } :
            {
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100%',
                minWidth: '600px',
                minHeight: '600px',
                padding: '20px 40px',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                align: 'center',
                justify: 'center'
            },
            // backgroundColor: '#eaf1f8'

        fontFlex: {
            fontSize:
                isMobile() ?
                    '16px' : '22px'
        },
        fontSignup: {
            color: 'rgba(255, 255, 255, 1)',
            fontSize: '18px'
            // fontColor: 'color: rgba(255, 255, 255, 1)'
        },

        width20: {
            width: '20%'
        },
        width40: {
            width: '40%'
        },

        cellTable: {
            fontSize:
                isMobile() ?
                    '12px' : '14px',
            width:
                '20%'
        },
        cellContainer: {
            // display: 'block'
            borderTop: 'none',
            maxWidth: '300px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },

        carouselWidth:
            isMobile() ?
            '90%' : '55%',

        fontTableHeader: {
            fontSize:
                isMobile() ?
                    '12px' : '20px'
        },
        fontTable: {
            fontSize:
                isMobile() ?
                    '10px' : '16px'
        },
        fontMenubar: {
            fontSize:
                isMobile() ?
                    '16px' : '16px'
        },
        fontFooter: {
            fontSize:
                isMobile() ?
                    '14px' : '18px'
        },
        fontFooterMini: {
            fontSize:
                isMobile() ?
                    '11px' : '14px'
        },
        iconSize:
            isMobile() ?
                'large' : 'large'
        ,

        widthFull: {
            width: '100%'
        },

        mainBody:
            isMobile() ?
                {
                    top: 'auto',
                    bottom: 'auto',
                    right: 'auto',
                    left: 'auto',
                    lineHeight: '2',
                    fontFamily: 'Arial',
                    fontSize: '16px',
                    fontWeight: 'normal',
                    color: '#000080',
                    position: 'relative',
                    flex: '1'
                    // fontWeight: '500',
                    // fontFamily: 'Yonsei',
                    // fontFamily: 'Lemon'
                } :
                {
                    top: 'auto',
                    bottom: 'auto',
                    right: 'auto',
                    left: 'auto',
                    margin: '30px',
                    marginBottom: '30px',
                    lineHeight: '2',
                    fontFamily: 'Arial',
                    fontSize: '22px',
                    fontWeight: 'normal',
                    color: '#000080',
                    position: 'relative',
                    flex: '1'
                    // fontFamily: 'Lemon'
                    // fontFamily: 'Arial'
                },

        imageBody:
            isMobile() ?
                {
                    top: 'auto',
                    bottom: 'auto',
                    right: 'auto',
                    left: 'auto',
                    width: '85%',
                    lineHeight: '2',
                    fontFamily: 'Arial',
                    fontSize: '16px',
                    fontWeight: 'normal',
                    color: '#000080',
                    position: 'relative',
                    flex: '1'
                    // fontWeight: '500',
                    // fontFamily: 'Yonsei',
                    // fontFamily: 'Lemon'
                } :
                {
                    top: 'auto',
                    bottom: 'auto',
                    right: 'auto',
                    left: 'auto',
                    width: '55%',
                    // margin: '150px',
                    marginTop: '40px',
                    marginBottom: '30px',
                    lineHeight: '2',
                    fontFamily: 'Arial',
                    fontSize: '22px',
                    fontWeight: 'normal',
                    color: '#000080',
                    position: 'relative',
                    flex: '1'
                    // fontFamily: 'Lemon'
                    // fontFamily: 'Arial'
                },

        infoBody:
            isMobile() ?
                {
                    top: 'auto',
                    bottom: 'auto',
                    right: 'auto',
                    left: 'auto',
                    lineHeight: '2',
                    fontSize: '1.3em',
                    // fontWeight: '500',
                    fontFamily: 'Arial',
                    flex: '1'
                    // fontFamily: 'Lemon'
                    // fontFamily: 'Arial'
                } :
                {
                    top: 'auto',
                    bottom: 'auto',
                    right: 'auto',
                    left: 'auto',
                    margin: '30px',
                    marginBottom: '30px',
                    lineHeight: '2',
                    fontSize: '1.5em',
                    color: '#000080',
                    fontWeight: 'normal',
                    // '500',
                    fontFamily: 'Arial',
                    position: 'relative',
                    flex: '1'
                    // fontFamily: 'Lemon'
                    // fontFamily: 'Arial'
                },

        wrapperSameLine:
            isMobile() ?
                {
                    // marginLeft: '100px',
                    marginTop: '50px',
                    width: '600px',
                    // overflow: 'hidden'
                } :
                {
                    // marginLeft: '250px',
                    marginTop: '50px',
                    width: '1400px',
                    // overflow: 'hidden'
                },

        firstElem:
            isMobile() ?
                {
                    // marginBottom: '10px',
                    width: '150px',
                    float: 'left'
                } :
                {
                    // marginBottom: '10px',
                    width: '200px',
                    float: 'left'
                },
        secondElem:
            isMobile() ?
                {
                    /*border: '1px solid green'*/
                    marginLeft: '100px',
                    float: 'left'
                    // overflow: 'hidden'
                } :
                {
                    /*border: '1px solid green'*/
                    marginLeft: '100px',
                    float: 'left'
                    // overflow: 'hidden'
                },

        mainLogin:

            isMobile() ?
                {
                    position: 'relative',
                    flex: '1'
                } :
                {
                    position: 'relative',
                    flex: '1',
                },

        loginButton:
            isMobile() ?
            {
                fontSize: '12px',
                height: '36px'
            }:
            {
                fontSize: '18px',
                height: '46px'
            },

        modalButton:
            isMobile() ?
            {
                fontSize: '16px'
            }:
            {
                fontSize: '18px'
            },

        textShort: {
            textOverflow: 'ellipsis'
        },

        triangleContainer:

            isMobile() ?
                // triangleContainer: {
                {
                    width: '400px',
                    height: '650px',
                    /*margin: auto;*/
                    textAlign: 'center',
                    /*background: #AA3A3B;*/
                    border: '1px solid white'
                } :
                {
                    width: '500px',
                    height: '650px',
                    /*margin: auto;*/
                    textAlign: 'center',
                    margin: '20px',
                    /*background: #AA3A3B;*/
                    // border: '5px outset deepskyblue'
                    border: '5px inset blueviolet'
                },

        svgShape:

            isMobile() ?
                {
                    height: '400px',
                    width: getWidth()
                } :
                {
                    height: '460px',
                    width: '500px'
                },


        paperElev: 4,

        paper: {
            width: '100%',
            // height: '250px',
            marginBottom: 16,
        },
        table: {
            width: 700,
            minWidth: 600
            // height: 250
        },

        distanceContainer: {
            position: 'absolute',
            // top: 10,
            left: 30,
            zIndex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            // fontSize: 13,
            // lineHeight: 18,
            display: 'block',
            margin: 0,
            // paddingLeft: '30',
            padding: '5 10',
            borderRadius: 3
        },

        outerDiv: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px'
        },
        middleDiv: {
            display: 'table-cell',
            verticalAlign: 'middle'
        },
        innerDiv: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '400px'
        },

        topColumn: {
            display: 'flex'
        },

        leftColumn: {
            float: 'left',
            // width: '15%',
            padding: '10px',
            marginRight: '50px'
            // height: '700px'
        },

        oneColumn: {
            float: 'left',
            width: '50%',
            padding: '10px',
            height: '100%',
            // height: '700px',
            // verticalAlign: 'middle'
        },

        littleColumn:
            isMobile() ?
            {
                float: 'left',
                marginRight: '40px',
                paddingLeft: '50px'
            } :
            {
                float: 'left',
                marginRight: '40px',
                paddingLeft: '110px'
            },
            // width: '20%'
            // padding: '10px',
            // height: '100%'

        bigColumn: {
            float: 'left',
            marginLeft: '30px',
            marginRight: '30px',
            // width: '60%'
            // padding: '10px',
            // height: '100%'
        },

        threeColumn: {
            float: 'left',
            width: '33.3333%'
            // padding: '10px',
            // height: '100%'
        },

        ceoTitle: {
            fontSize: '28px'
        },

        accented: {
            fontSize: '24px'
        },

        lineHeight2: {
            lineHeight: '2'
        },

        lineHeightWide: {
            lineHeight: '2'
        },

        verticalCenter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
            // margin: '0',
            // position: 'absolute',
            // top: '50%',
            // msTransform: 'translateY(-50%)',
            // transform: 'translateY(-50%)'
        },

        mainRow: {
            after: {
                content: '',
                display: 'table',
                clear: 'both'
            }
        }

        // textField: { width: 300, height: 40}
        // triangleContainer: { }
        /*    fontButton: {
                fontSize:
                isMobile()?
                '12px': '14px'

            },*/
    };

const Gradients = {
    rotate: 'rotate(70)',
    colorOne: 'rgba(113, 61, 224, 82)',
    // colorOne: 'rgba(2,0,36,1)',
    colorOnePct: '0%',
    // colorTwo: 'rgba(53,129,230,0.53125)',
    // colorTwo: 'rgba(83,18,217,0.8225665266106442)',
    colorTwo: 'rgba(129,23,182,0.909297052154195)',
    colorTwoPct: '34%',
    colorThree: 'rgba(0,212,255,1)',
    colorThreePct: '100%'
};

const GradientsDarker = {
    rotate: 'rotate(90)',
    background: 'rgb(2,0,36)',
    colorOne: 'rgba(2,0,36,1)',
    colorOnePct: '0%',
    // colorTwo: 'rgba(83,76,182,0.53125)',
    // colorTwo: 'rgba(53,129,230,0.53125)',
    colorTwo: 'rgba(83,18,217,0.8225665266106442)',
    colorTwoPct: '30%',
    // colorTwoPct: '52%',
    colorThree: '',
    colorThreePct: '0%'
};

const svgTriangle = {
    triangle:

    isMobile()?
    {
        topPoint: '60',
        topTriangle: '80',
        leftTriangle: '80',
        hmidPoint: getWidth()/2,
        vmidPoint: '230',
        leftPoint: '60',
        rightPoint: getWidth() - 80,
        bottomPoint: '320',
        bottomOffset: '380',
        rightTriangle: getWidth() - 60
    }:
    {
        topPoint: '80',
        topTriangle: '100',
        leftTriangle: '100',
        hmidPoint: '250',
        vmidPoint: '270',
        leftPoint: '60',
        rightPoint: '400',
        bottomPoint: '400',
        bottomOffset: '440',
        rightTriangle: '440'
    }


}

const midPoint =
    isMobile()? 200:
    250;

// const midPoint = 250;
const topPoint = 80;
const leftPoint = 60;
const bottomPoint = 400;
const bottomOffset = bottomPoint + 40;

const Triangle = [
    [svgTriangle.triangle.hmidPoint, svgTriangle.triangle.topTriangle],
    [svgTriangle.triangle.leftTriangle, svgTriangle.triangle.bottomPoint],
    [svgTriangle.triangle.rightPoint, svgTriangle.triangle.bottomPoint]
];

// isMobile()?
//     {
//         topPoint: '60',
//         midPoint: '200',
//         leftPoint: '40',
//         bottomPoint: '300'
//     }:
//     {
//         topPoint: '80',
//         midPoint: '250',
//         leftPoint: '60',
//         bottomPoint: '400'
//     }

const TextPos = {
    hmidPoint: svgTriangle.triangle.hmidPoint,
    vmidPoint: svgTriangle.triangle.vmidPoint,
    topPoint: svgTriangle.triangle.topPoint,
    leftPoint: svgTriangle.triangle.leftPoint,
    rightPoint: svgTriangle.triangle.rightTriangle,
    // bottomPoint: 400,
    // bottomOffset: 440
    bottomPoint: svgTriangle.triangle.bottomPoint,
    bottomOffset: svgTriangle.triangle.bottomOffset
}

export {AllStyles};
export {Gradients, GradientsDarker, Triangle, TextPos, isMobile, setWidth, setHeight, getCurrWidth, getCurrHeight,
    setColorRange, getAddrWidth};
