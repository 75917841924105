import React, { Component } from 'react';
import { NavLinksBar } from '../Navbar';
import mapboxgl from "mapbox-gl";
import Constants from '../Constants';

import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';
// import { Home, Maps } from '../pages/AllPages.js';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';

import MapFunc from './MapFunc';
import '../Custom.css';
import {AllStyles, setHeight, setWidth} from "../styles/AllStyles";
import axios from "axios";
import moment from 'moment';
import { mapLabelsCommon, mapStylesCommon } from './Common';
import {Navbar} from "react-bootstrap";
import {isMobile} from '../styles/AllStyles';

const monthView = 'dayGridMonth';
const weekView = 'timeGridWeek';
const dayView = 'timeGridDay';
mapboxgl.accessToken = 'pk.eyJ1IjoibWNzb25pYzciLCJhIjoiY2tienpqNDhzMWVjbDJzbGc0ZHp1ZzhxciJ9.cQ5RwSwMgo_20N5XwvxCOg';
const mainLon = 126.935367;
const mainLat = 37.554935;  // 37.554935;
let mainTitle = '연세유아/아동놀이교육원';
let mainTitleHTML = " <p style=\"color:blue; font-size:14px \">연세유아/아동놀이교육원";
let mapBox = '';

export default class Location extends Component {

    calendarRef = React.createRef();

    cultures = ['ko-KR'];
    theme1 = 'bootstrap';

    state = {
        weekendsVisible: true,
        currentEvents: []
    }

    constructor(props) {
        super(props);

        this.updateDimensions = this.updateDimensions.bind(this);
        this.getAll();
        this.state = {
            lng: mainLon,
            lat: mainLat,
            zoom: 16
        }
        // console.info("[Frontend] Calendar 1 historyDepth: " + JSON.stringify(historyDepth, null, 4));
    }

    updateDimensions = () => {
        let newWidth = window.innerWidth;
        let newHeight = window.innerHeight;
        console.log('[Frontend] Signup updateDimensions width: ' + newWidth + ', height: ' + newHeight);
        // const currHeight = window.innerWidth >= 600 ? window.innerHeight : 400
        this.setState({currWidth: newWidth});
        this.setState({currHeight: newHeight});
    }

    componentDidMount() {
        this.createMap();
        this.updateDimensions();
    }

    componentWillMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    getAll = () => {

    }

    createMap() {
        mapBox = new mapboxgl.Map({
            container: this.mapContainer,
            style: mapStylesCommon.vworld_base,  // mapbox_base
            // style: 'mapbox://styles/mapbox/light-v10',
            // style: 'mapbox://styles/mapbox/streets-v11',
            center: [this.state.lng, this.state.lat],
            // bearing: -60, // bearing in degrees
            zoom: this.state.zoom
        });

        /*        let marker = new mapboxgl.Marker()
                    .setLngLat([brownLon, brownLat])
                    .addTo(mapBox);*/

        /*        let popup = new mapboxgl.Popup({ closeOnClick: true })
                    .setLngLat([brownLon, brownLat + 0.0003])
                    .setHTML('<h4>Brown Court</h4>')
                    .addTo(mapBox);*/

        mapBox.addControl(new mapboxgl.NavigationControl());

        // GeoJSON object to hold measurements
        let geoJsonDraw = {
            'type': 'FeatureCollection',
            'features': []
        };

        let linestring = {
            'type': 'Feature',
            'geometry': {
                'type': 'LineString',
                'coordinates': []
            }
        };

        mapBox.on('load', async() => {  // style.load
            // mapBox.on('load', function() {  // style.load

            // console.log('mapBox on load: ' +  JSON.stringify(mapBox.getStyle(), null, 4));
            var layers = mapBox.getStyle().layers;
            var labelLayerId;
            for (var i = 0; i < layers.length; i++) {
                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                    labelLayerId = layers[i].id;
                    break;
                }
            }

            /*var getCentroid = function (arr)
            {
                var minX, maxX, minY, maxY;
                console.log("[Frontend] AdDetails all-data Mouse enter getCentroid: " + arr);
                for (var i = 0; i < arr.length; i++)
                {
                    minX = (arr[i][0] < minX || minX == null) ? arr[i][0] : minX;
                    maxX = (arr[i][0] > maxX || maxX == null) ? arr[i][0] : maxX;
                    minY = (arr[i][1] < minY || minY == null) ? arr[i][1] : minY;
                    maxY = (arr[i][1] > maxY || maxY == null) ? arr[i][1] : maxY;
                }
                return [(minX + maxX) / 2, (minY + maxY) / 2];
            }

            mapBox.addLayer(
                {
                    'id': '3d-buildings',
                    'source': 'composite',
                    'source-layer': 'building',
                    'filter': ['==', 'extrude', 'true'],
                    'type': 'fill-extrusion',
                    'minzoom': 15,
                    'paint': {
                        'fill-extrusion-color': '#aaa',

// use an 'interpolate' expression to add a smooth transition effect to the
// buildings as the user zooms in
                        'fill-extrusion-height': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'height']
                        ],
                        'fill-extrusion-base': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'min_height']
                        ],
                        'fill-extrusion-opacity': 0.6
                    }
                },
                labelLayerId
            );*/

            mapBox.resize();
            mapBox.addSource("all-data", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });
            mapBox.addSource("soarea", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });

            mapBox.loadImage(
                'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
                // Add an image to use as a custom marker
                function (error, image) {
                    if (error) throw error;
                    mapBox.addImage('custom-marker', image);

                    mapBox.addSource('places', {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [
                                {
                                    type: 'Feature',
                                    properties: {
                                        description:
                                            '<strong><p>연세유아/아동놀이교육원</p></strong>'
                                    },
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [mainLon, mainLat]
                                    }
                                }
                            ]
                        }
                    });

                    mapBox.addLayer({
                        'id': 'places',
                        'type': 'symbol',
                        'source': 'places',
                        'layout': {
                            'icon-image': 'custom-marker',
                            'icon-allow-overlap': true
                        }
                    });
                    /*                    mapBox.addLayer({
                                            id: "hoverData",
                                            source: "all-data",
                                            type: 'circle',
                                            // type: "symbol"

                                        });*/
                }
            );

            var popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            mapBox.addSource('geoJsonDraw', {
                'type': 'geojson',
                'data': geoJsonDraw
            });

// Add styles to the map
            mapBox.addLayer({
                id: 'measure-points',
                type: 'circle',
                source: 'geoJsonDraw',
                paint: {
                    'circle-radius': 5,
                    'circle-color': '#000'
                },
                filter: ['in', '$type', 'Point']
            });
            mapBox.addLayer({
                id: 'measure-lines',
                type: 'line',
                source: 'geoJsonDraw',
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': '#000',
                    'line-width': 2.5
                },
                filter: ['in', '$type', 'LineString']
            });

            // get new center coordinates
            const { lng, lat } = mapBox.getCenter();
            console.log("Moved end: " + lng);
            let id = 50;
            var firstTower = false;

/*            mapBox.on('mouseenter', 'places', function (e) {
                mapBox.getCanvas().style.cursor = 'pointer';

                var coordinates = e.features[0].geometry.coordinates.slice();
                // let description = e.features[0].properties.description;
                let description = mainTitle;

                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                // Populate the popup and set its coordinates
                // based on the feature found.
                console.log("Mouse enter: " + coordinates);
                popup.setLngLat(coordinates).setHTML(description).addTo(mapBox);
            });*/

            let coordinates = '126.93536728620529,37.55493507161289';
            popup.setLngLat([mainLon, mainLat+0.0001]).setHTML(mainTitleHTML).addTo(mapBox);

            // mapBox.on('mouseleave', 'places', function () {
            //     mapBox.getCanvas().style.cursor = '';
            //     if (popup) {
            //         popup.remove();
            //     }
            // });

            mapBox.on('data', function() {  // styledata data
                console.log("mapBox on tower: " + firstTower);
                // if (!firstTower) {
                //     updateTower();
                // }
            });

            mapBox.on('move', () => {
                console.log("[Frontend] AdDetails mapBox on move: " + firstTower);
                // this.setState({
                //     lng: mapBox.getCenter().lng.toFixed(4),
                //     lat: mapBox.getCenter().lat.toFixed(4),
                //     zoom: mapBox.getZoom().toFixed(2)
                // });
                // updateTower();
            });

        });

        this.setState({selectedOption: 'option2'});
        this.setState({mapBox: mapBox});
    }

    render() {
        // return (
        //     <div><MapFunc/></div>
        // ) ...AllStyles.verticalCenter  ◈

        return (
            <>
            <NavLinksBar/>
            {!isMobile() ?
            <div className='demo-app' style={{...AllStyles.mainBody}}>
                {/*<div style={AllStyle.middleDiv}>*/}
                <div style={{...AllStyles.oneColumn, paddingLeft: '40px', paddingTop: '0px'}} >
                    <div style={AllStyles.accented}>

                        <div style={{...AllStyles.oneColumn, paddingLeft: '40px'}}>
                        <img
                            src="img/mickey-windows.jpg"
                            alt="logo"
                            width="300"
                            // height="80"
                            className="d-inline-block "
                        />
                        </div>

                        <div style={{...AllStyles.oneColumn, paddingTop: '70px'}}>
                        &emsp;&nbsp;&nbsp;☎&nbsp;&nbsp;{Constants.textContacts}<br/></div>
                        &emsp;&emsp;{Constants.officeEmail}<br/>
                        &emsp;&emsp;{Constants.officePhone}<br/><br/><br/><br/>
                        </div>

                    <br/>
                    <div style={{...AllStyles.accented}}>
                    &emsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9884;&nbsp;&nbsp;{Constants.textDirections}<br/>
                    &emsp;&emsp;{Constants.officeAddr}<br/><br/>
                    &emsp;&emsp;{Constants.officeAddr2}<br/><br/>
                    &emsp;&emsp;{Constants.officeAddr3}<br/><br/>

                    </div>

                </div>
                {/*</div>*/  }

                <div style={AllStyles.oneColumn}>
                    <div className="map-wrapper" style={{...AllStyles.card}}>
                        <div ref={el => this.mapContainer = el} className={'mapContainer'} />
                    </div>
                </div>

            </div> :
                <div className='demo-app' style={{...AllStyles.mainBody}}>
                    {/*<div style={AllStyle.middleDiv}>*/}
                    <div className="map-wrapper" style={{width: this.state.currWidth-10, height: this.state.currHeight/2,
                        paddingLeft: '0px', paddingTop: '0px'}}>
                        <div ref={el => this.mapContainer = el} className={'mapContainer'} />
                    </div>
                    <div style={{paddingLeft: '20px', paddingTop: '20px'}} >
                        <div style={{fontSize: '16px', paddingLeft: '10px'}}>

{/*                            <div style={{...AllStyles.oneColumn, paddingLeft: '40px'}}>
                                <img
                                    src="img/mickey-windows.jpg"
                                    alt="logo"
                                    width="300"
                                    // height="80"
                                    className="d-inline-block "
                                />
                            </div>*/}

                            ☎&nbsp;&nbsp;{Constants.textContacts}<br/>
                            {Constants.officeEmail}<br/>
                            {Constants.officePhone}<br/><br/>

                            &#9884;&nbsp;&nbsp;{Constants.textDirections}<br/>
                            {Constants.officeAddrM1}<br/>
                            {Constants.officeAddrM2}<br/><br/>
                            {Constants.officeAddr2}<br/><br/>
                            {Constants.officeAddr3}

                        </div>

{/*                        <div style={{...AllStyles.accented}}>
                            &emsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9884;&nbsp;&nbsp;{Constants.textDirections}<br/>
                            &emsp;&emsp;{Constants.officeAddr}<br/><br/>
                            &emsp;&emsp;{Constants.officeAddr2}<br/><br/>
                            &emsp;&emsp;{Constants.officeAddr3}<br/><br/>
                        </div>*/}

                    </div>
                    {/*</div>*/  }


                </div> }
            </>

        )
    }
}
