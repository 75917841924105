import axios from 'axios'

class AuthenticationService {

    // send username, password to the SERVER
    executeJwtAuthenticationService (username, password) {
        return axios.post('http://localhost:8090/authenticate', {
            username,
            password
        })
    }

    executeHelloService() {
        console.log("===executeHelloService===")
        return axios.get('http://localhost:8090/hello');        
    }

    registerSuccessfulLoginForJwt (username, token) {
        console.log("[Frontend] AuthenticationService ===registerSuccessfulLoginForJwt===")
        localStorage.setItem('token', token);
        localStorage.setItem('authUser', username);
        // sessionStorage.setItem('authUser', username)
        //this.setupAxiosInterceptors(this.createJWTToken(token))
        this.setupAxiosInterceptors();
    }

    createJWTToken(token) {
        return 'Bearer ' + token
    }

    setupAxiosInterceptors() {
        console.log("[Frontend] AuthenticationService ===setupAxiosInterceptors===")
        axios.interceptors.request.use(
            config => {
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
                config.headers['Content-Type'] = 'application/json';
                console.log('[Frontend] AuthenticationService setupAxiosInterceptors() config.headers: ' +
                    config.headers['Authorization'] + ', config: ' + JSON.stringify(config, null, 4));
                return config;
            },
            error => {
                Promise.reject(error)
                console.log('setupAxiosInterceptors error: ' + error);
            });
    }

    logout() {
        //sessionStorage.removeItem('authUser');
        const token = localStorage.getItem('token');
        console.log('[Frontend] AuthenticationService === logout === \n' + token);
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
    }

    isUserLoggedIn() {
        //let user = sessionStorage.getItem('authUser')
        const token = localStorage.getItem('token');
        console.log('[Frontend] AuthenticationService isUserLoggedIn() === UserloggedInCheck === \n' + token);
        if (token) {
            return true;
        }
        //if(user===null) return false
        return false;
    }

    getLoggedInUserName() {
        //let user = sessionStorage.getItem('authUser')
        let user = localStorage.getItem('authUser');
        console.log('[Frontend] AuthenticationService === getLoggedInUserName === \n' + user);
        if (user===null) {
            return '';
        }
        return user;
    }

    async isAdminLoggedIn() {
        let isAdmin = false;
        let isLoggedIn = this.isUserLoggedIn();
        let userName = this.getLoggedInUserName();

        console.log('[Frontend] AuthenticationService userName: ' + userName + ', len: ' + userName.length);
        if (userName != null && userName.length > 0) {
            const response = await axios.get('/api/ipHistory/isAdmin?email=' + userName);
            isAdmin = response.data;
        }
        else {
            console.log('[Frontend] AuthenticationService not logged in: ' + JSON.stringify(isAdmin, null, 4));
            isAdmin = false;
        }
        console.log('[Frontend] AuthenticationService userName: ' + userName + ', isAdmin: ' + JSON.stringify(isAdmin, null, 4));
        return isAdmin;
        // return await Promise.resolve(isAdmin);
            /*.then(res => {
                this.isAdmin = res.data;
                console.log("[Frontend] Navbar isAdmin from /api/ipHistory/isAdmin: " + JSON.stringify(this.isAdmin));
                this.setState({showScreen: true});
            })
            .catch(error => {
                console.log(error);
                this.isAdmin = false;
                this.setState({showScreen: false});
                console.log("[Frontend] Navbar isAdmin ERROR: " + this.isAdmin);
            });*/
    }


}

export default new AuthenticationService()
