import React, {Component, useRef, createRef} from 'react';
import { NavLinksBar } from '../Navbar';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Alert } from "react-bootstrap"
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import { Icon } from 'semantic-ui-react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button as ButtonSem } from 'semantic-ui-react';
import SendIcon from "@material-ui/icons/Send"
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {isMobile} from '../styles/AllStyles';
// import Icon from '@material-ui/core/Icon';
import {Link, Redirect} from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Modal } from 'semantic-ui-react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import '../style.css';
import moment from 'moment';
import {AllStyles, getAddrWidth, setWidth, setHeight} from "../styles/AllStyles";
import {Comment} from "semantic-ui-react";
import axios from "axios";
import DaumPostcode from "react-daum-postcode";
import FindAddr from './FindAddr';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ReactDOM from 'react-dom';
import Constants from "../Constants";
// import Modal from 'react-modal';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" to="https://material-ui.com/">
                유아놀이 교육원 (UA nori)
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const formSize = 'md'; // sm, lg
const formSpacing = 2;
const oneWidth = 6;
const twoWidths = 12;
const veriWidth = 4;
const veriWidthSm = 2;
const veriWidthMed = 3;
const veriWidthButton = oneWidth - veriWidth;
const btnType = 'btn';
const iconBtnType = 'iconBtn';
const bdayLen = 6;
const tokenLen = 6;
const phoneLen = 14;
const emailType = 'email';
const phoneType = 'tel';
const pwordType = 'password';
const bdayType = 'bday';
const genderType = 'gender';
const inputHeight = 56;
const signupText = '회원가입';
const formFilledGood = -1;

let pwCounter=0;  // pwCounter++
// 0 : Success, 1~ : Error codes
var pw_state = {
    PASSWORD_GOOD: pwCounter++,
    PASSWORD_EMPTY: pwCounter++,
    PASSWORD_BASIC_BAD: pwCounter++,
    PASSWORD_CONSEC_BAD: pwCounter++,
    PASSWORD_MISMATCH: pwCounter++,
    PASSWORD_CHECK_FIRST: pwCounter++
};

let emailCounter=0;
var email_state = {
    EMAIL_GOOD: emailCounter++,
    EMAIL_EMPTY: emailCounter++,
    EMAIL_BASIC_BAD: emailCounter++,
    EMAIL_EXISTS: emailCounter++,
};

let fieldCounter=0;
var field_state = {
    FIELD_NAME: fieldCounter++,
    FIELD_BDAY: fieldCounter++,
    FIELD_PWORD: fieldCounter++,
    FIELD_PWORD2: fieldCounter++,
    FIELD_EMAIL: fieldCounter++,
    FIELD_EMAILBTN: fieldCounter++,
    FIELD_EMAILCONFIRM: fieldCounter++,
    FIELD_EMAILCONFIRMBTN: fieldCounter++,
    FIELD_PHONE: fieldCounter++,
    // FIELD_PHONEBTN: fieldCounter++,
    // FIELD_PHONECONFIRM: fieldCounter++,
    // FIELD_PHONECONFIRMBTN: fieldCounter++,
    FIELD_ADDRBTN: fieldCounter++,
    FIELD_ADDR: fieldCounter++,
    FIELD_ADDRDETAILS: fieldCounter++,
    FIELD_CHILD1NAME: fieldCounter++,
    FIELD_CHILD1BDAY: fieldCounter++,
    FIELD_CHILD2NAME: fieldCounter++,
    FIELD_CHILD2BDAY: fieldCounter++,
    FIELD_CHILD3NAME: fieldCounter++,
    FIELD_CHILD3BDAY: fieldCounter++
};

let pwMapping = {};
pwMapping[pw_state.PASSWORD_GOOD] = '비밀번호 OK!';
pwMapping[pw_state.PASSWORD_EMPTY] = '';
pwMapping[pw_state.PASSWORD_BASIC_BAD] = '8~16자 길이, 영문 + 숫자 + 특수문자 조합';
pwMapping[pw_state.PASSWORD_CONSEC_BAD] = '동일 또는 연속 문자 3회 금지 (aaa,123)';
pwMapping[pw_state.PASSWORD_MISMATCH] = '비밀번호가 일치하지 않습니다';
pwMapping[pw_state.PASSWORD_CHECK_FIRST] = '비밀번호 조건을 확인하세요';

let emailMapping = {};
emailMapping[email_state.EMAIL_GOOD] = '사용 가능한 이메일 주소입니다';
emailMapping[email_state.EMAIL_EMPTY] = '';
emailMapping[email_state.EMAIL_BASIC_BAD] = '이메일 형식을 다시 확인하세요';
emailMapping[email_state.EMAIL_EXISTS] = '이미 사용중입니다';

let fieldMapping = {};
fieldMapping[field_state.FIELD_NAME] = 'name';
fieldMapping[field_state.FIELD_BDAY] = 'bday';
fieldMapping[field_state.FIELD_PWORD] = 'pword';
fieldMapping[field_state.FIELD_PWORD2] = 'pword2';
fieldMapping[field_state.FIELD_EMAIL] = 'email';
fieldMapping[field_state.FIELD_EMAILBTN] = 'emailBtn';
fieldMapping[field_state.FIELD_EMAILCONFIRM] = 'emailConfirm';
fieldMapping[field_state.FIELD_EMAILCONFIRMBTN] = 'emailConfirmBtn';
fieldMapping[field_state.FIELD_PHONE] = 'phone';
fieldMapping[field_state.FIELD_PHONEBTN] = 'phoneBtn';
fieldMapping[field_state.FIELD_PHONECONFIRM] = 'phoneConfirm';
fieldMapping[field_state.FIELD_PHONECONFIRMBTN] = 'phoneConfirmBtn';
fieldMapping[field_state.FIELD_ADDRBTN] = 'addrBtn';
fieldMapping[field_state.FIELD_ADDR] = 'addr';
fieldMapping[field_state.FIELD_ADDRDETAILS] = 'addrDetails';
fieldMapping[field_state.FIELD_CHILD1NAME] = 'child1Name';
fieldMapping[field_state.FIELD_CHILD1BDAY] = 'child1Bday';
fieldMapping[field_state.FIELD_CHILD2NAME] = 'child2Name';
fieldMapping[field_state.FIELD_CHILD2BDAY] = 'child2Bday';
fieldMapping[field_state.FIELD_CHILD3NAME] = 'child3Name';
fieldMapping[field_state.FIELD_CHILD3BDAY] = 'child3Bday';

// these do not need to be filled in form
let optionals = ["child2Name", "child2Bday", "child3Name", "child3Bday"];


// passwordSub: '동일 또는 연속 문자 3회 금지 (aaa,123)',
//     passwordGood: '비밀번호 OK!',
//     passwordCheckBad: '비밀번호가 일치하지 않습니다',
//     passwordCheckGood: '비밀번호가 일치합니다!',

const errMsgKor = {
    tel: '번호만 입력 가능합니다',
    phoneGood: '사용 가능한 전화번호 입니다',
    email: '이메일 형식을 다시 확인하세요',
    emailGood: '사용 가능한 이메일 주소입니다',
    bday: '주민등록번호 6자리 (생년월일) 형식을 다시 확인하세요',
    bdayGood: '',
    passwordEmpty: '',
    password: '8~16자 길이, 영문 + 숫자 + 특수문자 조합',
    passwordSub: '동일 또는 연속 문자 3회 금지 (aaa,123)',
    passwordGood: '비밀번호 OK!',
    passwordCheckBad: '비밀번호가 일치하지 않습니다',
    passwordCheckGood: '비밀번호가 일치합니다!',
    gender: '성별을 선택하세요'
};

const fields =  [
    {title: fieldMapping[field_state.FIELD_NAME], titleKor: '이름', type: 'text', dataType: 'text',
        width: oneWidth, required: true, disabled: false},
    {title: fieldMapping[field_state.FIELD_BDAY], titleKor: '주민등록번호 6자리 (생년월일)', type: 'tel', dataType: 'bday',
        width: oneWidth, maxLen: bdayLen, required: true, disabled: false},
    {title: fieldMapping[field_state.FIELD_PWORD], titleKor: '비밀번호', type: 'password', dataType: 'password',
        width: oneWidth/2, required: true, disabled: false},
    {title: fieldMapping[field_state.FIELD_PWORD2], titleKor: '비밀번호 확인', type: 'password', dataType: 'password',
        width: oneWidth/2, required: true, disabled: false},
    {title: fieldMapping[field_state.FIELD_PHONE], titleKor: '휴대폰 번호', type: 'tel', dataType: 'tel',
        width: oneWidth, maxLen: phoneLen, required: true, disabled: false},
    {title: fieldMapping[field_state.FIELD_EMAIL], titleKor: '이메일 주소', type: 'email', dataType: 'email',
        width: veriWidth, required: true, disabled: false},
    {title: fieldMapping[field_state.FIELD_EMAILBTN], titleKor: '인증', type: 'btn', width: veriWidthButton, disabled: false},
    {title: fieldMapping[field_state.FIELD_EMAILCONFIRM], placeholder: '10분내 입력하세요', titleKor: '인증번호', type: 'tel',
        dataType: 'text', width: veriWidth, maxLen: tokenLen, required: true, disabled: false},
    {title: fieldMapping[field_state.FIELD_EMAILCONFIRMBTN], titleKor: '확인', type: 'btn', width: veriWidthButton, disabled: false},
    // {title: fieldMapping[field_state.FIELD_PHONE], titleKor: '휴대폰 번호', type: 'tel', dataType: 'tel', width: veriWidth, maxLen: phoneLen},
    // {title: fieldMapping[field_state.FIELD_PHONEBTN], titleKor: '인증', type: 'btn', width: veriWidthButton},
    // {title: fieldMapping[field_state.FIELD_PHONECONFIRM], placeholder: '10분내 입력하세요', titleKor: '인증번호', type: 'tel', dataType: 'text', width: veriWidth, maxLen: tokenLen},
    // {title: fieldMapping[field_state.FIELD_PHONECONFIRMBTN], titleKor: '확인', type: 'btn', width: veriWidthButton},
    {title: fieldMapping[field_state.FIELD_ADDRBTN], titleKor: '주소검색', type: 'btn', width: veriWidthButton, disabled: false},
    {title: fieldMapping[field_state.FIELD_ADDR], titleKor: '주소', type: 'text', dataType: 'text',
        width: twoWidths - veriWidthButton - veriWidthMed, required: true, disabled: true},
    {title: fieldMapping[field_state.FIELD_ADDRDETAILS], titleKor: '상세주소', type: 'text', dataType: 'text',
        width: veriWidthMed, required: true, disabled: false},

    {title: fieldMapping[field_state.FIELD_CHILD1NAME], titleKor: '첫째 아이 이름', type: 'text', dataType: 'text',
        width: oneWidth, required: true, disabled: false},
    {title: fieldMapping[field_state.FIELD_CHILD1BDAY], titleKor: '첫째 아이 생일', type: 'tel', dataType: 'bday',
        width: oneWidth, maxLen: bdayLen, required: true, disabled: false},
    // {title: '', titleKor: '', type: 'iconBtn', width: veriWidthButton},

    {title: fieldMapping[field_state.FIELD_CHILD2NAME], titleKor: '둘째 아이 이름 (Optional)', type: 'text',
        dataType: 'text', width: oneWidth, required: false, disabled: false},
    {title: fieldMapping[field_state.FIELD_CHILD2BDAY], titleKor: '둘째 아이 생일 (Optional)', type: 'tel',
        dataType: 'bday', width: oneWidth, maxLen: bdayLen, required: false, disabled: false},
    {title: fieldMapping[field_state.FIELD_CHILD3NAME], titleKor: '셋째 아이 이름 (Optional)', type: 'text',
        dataType: 'text', width: oneWidth, required: false, disabled: false},
    {title: fieldMapping[field_state.FIELD_CHILD3BDAY], titleKor: '셋째 아이 생일 (Optional)', type: 'tel',
        dataType: 'bday', width: oneWidth, maxLen: bdayLen, required: false, disabled: false}
];

// const styles = makeStyles((theme) => ({
const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        fontSize: '18px'
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        // position: 'absolute',
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    button: {
        height: '56px'
        // position: 'absolute',
        // top: '50%',
        // transform: 'translateY(-50%)',
        // justifyContent: "center",
        // alignItems: "center",
        // verticalAlign: 'middle'
        // height: '56px'
        // display: "flex",
        // flexDirection: "column",
        // position: 'absolute',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

function insertHyphens(text) {
    var result = [];
    let noDashes = text.split('-').join('');
    let finalStr = '';
    // console.log('[Frontend] Signup insertHyphens() noDashes: ' + noDashes);

    for (let i=0; i<noDashes.length; i++) {
        if (i===3 || i===7) {
            finalStr = finalStr + '-';
            // noDashes = noDashes + '-';
            // console.log('[Frontend] Signup insertHyphens() noDashes: ' + noDashes);
        }
        {
            finalStr = finalStr + noDashes.charAt(i);
        }
    }

    console.log('[Frontend] Signup insertHyphens() final: ' + finalStr);
    return finalStr;
/*    text = text.replace(/^\d{2}-?\d{3}-?\d{3}-?\d{3}$/, "");
    while (text.length >= 6){
        result.push(text.substring(0, 3));
        text = text.substring(3);
    }
    if (text.length > 0) result.push(text);
    return result.join("-");*/
}

/*function validateEmail(text) {
    const reg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;
    if (!text) {
        return true;
    }
    else {
        return reg.test(text);
    }
}*/

function checkConsecutive(text) {
    // Check for sequential numerical characters
    for(let i in text) {
        if ((+text[+i + 1] === +text[i] + 1 && +text[+i + 2] === +text[i] + 2) ||
        (+text[+i + 1] === +text[i] && +text[+i + 2] === +text[i] )) {
            return false;
        }
    }
    // Check for sequential alphabetical characters
    for(let i in text) {
        if ((String.fromCharCode(text.charCodeAt(i) + 1) === text[+i + 1] &&
            String.fromCharCode(text.charCodeAt(i) + 2) === text[+i + 2]) ||
            (String.fromCharCode(text.charCodeAt(i)) === text[+i + 1] &&
                String.fromCharCode(text.charCodeAt(i)) === text[+i + 2])) {
            return false;
        }
    }
    return true;
}

function validateNumber(text) {
    let value = text.split('-').join('');
    if (!value) {
        return true;
    }
    else {
        return isNaN(value);
    }
}

function validateDate(text) {
    const dateFormat = 'YYMMDD';
    let res = moment(text, dateFormat, true).isValid();
    return !res;
}

function validatePassword(text) {
    // const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[@#$%^&-+=()]).{8, 16}$/;
    // const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8, 16}$/;
    const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

    if (!text) {
        return pw_state.PASSWORD_EMPTY;
    }
    else {
        let res = reg.test(text);
        let res2 = checkConsecutive(text);
        console.log('[Frontend] validateInput validatePassword res: ' + res + ', res2: ' + res2);
        console.log('[Frontend] validateInput good: ' + pw_state.PASSWORD_GOOD + ', bad: ' + pw_state.PASSWORD_BASIC_BAD);

        if (!res) {
            return pw_state.PASSWORD_BASIC_BAD;
        }
        else if (!res2) {
            return pw_state.PASSWORD_CONSEC_BAD;
        }
        else {
            return pw_state.PASSWORD_GOOD;
        }
    }
}

// const Signup = function() {
// const elemRef = useRef(collection.map(() => createRef()));
// const elemRef = useRef(fields.map(() => createRef()));
class Signup extends Component {

    mounted = false;
    state = {
        currWidth: window.innerWidth,
        currHeight: window.innerHeight,
        errorState: {
            name: false,
            gender: true,
            bday: false,
            pword: false,
            pword2: false,
            email: false,
            emailBtn: false,
            emailConfirm: true,
            emailConfirmBtn: false,
            phone: false,
            phoneBtn: false,
            phoneConfirm: false,
            phoneConfirmBtn: false,
            addrBtn: false,
            addr: false,
            addrDetails: false,
            child1Name: false,
            child1Bday: false,
            child2Name: false,
            child2Bday: false,
            child3Name: false,
            child3Bday: false
        },
        errorMsg: {
            phone: '',
            email: ''
        },
        icons: {
            emailConfirm: '',
            phoneConfirm: ''
        },
        formValues: {
            name: '',
            gender: '',
            bday: '',
            pword: '',
            pword2: '',
            email: '',
            // emailBtn: '',
            emailConfirm: '',
            // emailConfirmBtn: '',
            phone: '',
            // phoneBtn: '',
            // phoneConfirm: '',
            // phoneConfirmBtn: '',
            // addrBtn: '',
            addr: '',
            addrDetails: '',
            child1Name: '',
            child1Bday: '',
            child2Name: '',
            child2Bday: '',
            child3Name: '',
            child3Bday: '',
        },
        buttonEnabled: {
            emailBtn: true,
            emailConfirmBtn: true,
            phoneBtn: true,
            phoneConfirmBtn: true,
            addrBtn: true
        },
        isPostOpened: false,
        isPostVisible: true,
        allGeoItems: [
            {
                childName: '',
                childBday: '',
                // geoProp: ''
            }
        ],
        formIncomplete: '',
        signupSuccess: false,
        signupMessage: '',
        signupFullName: '',
        redirectToLogin: false
    };

    constructor(props) {
        super(props);
        this.elemRef = [];
        let newWidth = window.innerWidth;
        let newHeight = window.innerHeight;
        console.log('[Frontend] Signup updateDimensions width: ' + newWidth + ', height: ' + newHeight);

        setWidth(newWidth);
        setHeight(newHeight);
        // const currHeight = window.innerWidth >= 600 ? window.innerHeight : 400
        // this.state = { currWidth: '' };
        // this.state = {
        //     currHeight: newHeight
        // }
        // this.elemRef = useRef(fields.map(() => createRef()));
    }

    componentDidMount() {
        this.mounted = true;
        if (this.mounted) {
            this.setPopupVisible(true);
        }
    }

    isFormFilled = () => {
        let isFilled = formFilledGood;
        let valueFound = false;
        Object.keys(this.state.formValues).map((key, idx) => {
            if (this.state.formValues[key] === '' && !optionals.includes(key) && !valueFound) {
                console.log('[Frontend] Signup isFormFilled formValues NOT filled key: ' + key + ', value: ' +
                    this.state.formValues[key]);

                isFilled = idx;
                valueFound = true;
                return isFilled;
            }
            console.log('[Frontend] Signup isFormFilled formValues key: ' + key + ', value: ' +
                this.state.formValues[key]);
        });

        if (valueFound && isFilled !== formFilledGood) {
            return isFilled;
        }

        // console.log('[Frontend] Signup isFormFilled errorState: ' +
        //     JSON.stringify(this.state.errorState, null, 4));

        if (!valueFound) {
            Object.keys(this.state.errorState).map((key, idx) => {
                if (this.state.errorState[key] === true && !valueFound) {
                    console.log('[Frontend] Signup isFormFilled formValues Error key: ' + key + ', value: ' +
                        this.state.formValues[key]);

                    isFilled = idx;
                    valueFound = true;
                    return isFilled;
                }
                // console.log('[Frontend] Signup isFormFilled errorState key: ' + key + ', value: ' +
                //     this.state.formValues[key]);
            });
        }

        console.log('[Frontend] Signup isFormFilled final result isFilled: ' + isFilled);
        return isFilled;
    }

    validateEmail = (text, id) => {
        const reg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;
        if (!text) {
            return true;
        }
        else {
            let result;
            let emailRegex = reg.test(text);
            if (emailRegex) {
                result = this.checkExistingEmail(id);
                // return this.checkExistingEmail();
            }
            else {
                result = email_state.EMAIL_BASIC_BAD;
                // return email_state.EMAIL_BASIC_BAD;
            }
            console.log("[Frontend] Signup checkExistingEmail validateEmail result: " + JSON.stringify(result, null, 4));
            return result;
        }
    }

    msgConfirm = (id, event) => {
        // let {name, id, dataType, value} = event.target;

        let temp = '';
        console.log('[Frontend] Signup msgConfirm id: ' + id + ', email: ' + this.state.formValues['email']);
        // console.log('[Frontend] Signup msgConfirm id: ' + id + ', name: ' + name + ', event.target: ' +
        //     JSON.stringify(event.target, null, 4));
        if (id === fieldMapping[field_state.FIELD_EMAILBTN]) {
            axios.get('/api/members/emailConfirm?email=' + this.state.formValues['email'])
                .then(res => {
                    const resData = res.data;
                    console.log("[Frontend] Signup msgConfirm from /api/members/emailConfirm: " + JSON.stringify(resData));
                })
                .catch(error => {
                    // this.setState({ pword: '' });
                    // console.log("[Frontend] Signup checkExistingEmail Post Error: " +
                    //     JSON.stringify(error.response, null, 4) + ", pword: ");
                    return email_state.EMAIL_GOOD;
                });
        }
        else if (id === fieldMapping[field_state.FIELD_PHONEBTN]) {

        }
        else if (id === fieldMapping[field_state.FIELD_EMAILCONFIRMBTN]) {
            axios.post('/api/members/checkToken',
                    {
                        userName: this.state.formValues['email'],
                        token: this.state.formValues['emailConfirm']
                    }
                )
                .then(res => {
                    const resData = res.data;
                    if (resData) {
                        this.setState(prevState => {
                            let errorState = Object.assign({}, prevState.errorState);
                            errorState[fieldMapping[field_state.FIELD_EMAILCONFIRM]] = Boolean(resData.resultCode);
                            return {errorState};
                        });
                        this.setState(prevState => {
                            let errorState = Object.assign({}, prevState.errorState);
                            errorState[fieldMapping[field_state.FIELD_EMAILCONFIRMBTN]] = Boolean(resData.resultCode);
                            return {errorState};
                        });
                        this.setState(prevState => {
                            let errorMsg = Object.assign({}, prevState.errorMsg);
                            errorMsg[fieldMapping[field_state.FIELD_EMAILCONFIRM]] = resData.resultDesc;
                            return {errorMsg};
                        });

                        if (resData.resultCode === email_state.EMAIL_GOOD) {
                            this.setState(prevState => {
                                let icons = Object.assign({}, prevState.icons);
                                icons[fieldMapping[field_state.FIELD_EMAILCONFIRM]] = <CheckCircleOutline/>;
                                return {icons};
                            })

                            this.setState(prevState => {
                                let buttonEnabled = Object.assign({}, prevState.buttonEnabled);
                                buttonEnabled[`${id}`] = false;
                                return {buttonEnabled};
                            })

/*                            this.setState(prevState => {
                                let errorState = Object.assign({}, prevState.errorState);
                                errorState[fieldMapping[field_state.FIELD_EMAILCONFIRM]] = false;
                                return {errorState};
                            })*/

                        }
                        else {
                            this.setState(prevState => {
                                let icons = Object.assign({}, prevState.icons);
                                icons[fieldMapping[field_state.FIELD_EMAILCONFIRM]] = '';
                                return {icons};
                            })
                        }
                    }
                    console.log("[Frontend] Signup msgConfirm from /api/members/checkToken: " + JSON.stringify(resData));
                })
                .catch(error => {
                    // this.setState({ pword: '' });
                    // console.log("[Frontend] Signup checkExistingEmail Post Error: " +
                    //     JSON.stringify(error.response, null, 4) + ", pword: ");
                    return email_state.EMAIL_GOOD;
                });
        }
        else if (id === fieldMapping[field_state.FIELD_ADDRBTN]) {
            // this.setState({
            //     isPostOpened : true
            // })
            // sonic
            // this.openAddrPopup(true);

            console.log('[Frontend] Signup msgConfirm call address popup: ' + this.state.isPostOpened);
            // return (<div><FindAddr/></div>);
            // return <FindAddr/>;
            //     <div className='poup-text'>{score}</div>
            // </Popup>);
            // <FindAddr/>
        }
    }

    openAddrPopup = (flag) => {
        this.setState({
            isPostOpened : flag
        })
    }

    setPopupVisible = (flag) => {
        this.setState({
            isPostVisible : flag
        })
    }

    checkExistingEmail = (id) => {
        axios.get('/api/members/search/' + this.state.formValues['email'] )
        // axios.post('/api/members/search', {email: this.state.formValues['email']} )
            .then(res => {

                // this.state.commentsList = res.data;
                let resultState;
                const resData = res.data;
                console.log("[Frontend] Signup checkExistingEmail from /api/members/search: " + JSON.stringify(resData));
                // this.setState({ commentsList });
                if (resData > 0) {
                    resultState = email_state.EMAIL_EXISTS;
                    // return email_state.EMAIL_EXISTS;
                }
                else {
                    resultState = email_state.EMAIL_GOOD;
                    // return email_state.EMAIL_GOOD;
                }
                this.setState(prevState => {
                    let errorState = Object.assign({}, prevState.errorState);
                    errorState[`${id}`] = Boolean(resultState);
                    return { errorState };
                });
                let errMsg = emailMapping[resultState];
                this.setState(prevState => {
                    let errorMsg = Object.assign({}, prevState.errorMsg);
                    errorMsg[`${id}`] = errMsg;
                    return { errorMsg };
                });

                // this.setState({ fullName: '' });

            })
            .catch(error => {
                // this.setState({ pword: '' });
                // console.log("[Frontend] Signup checkExistingEmail Post Error: " +
                //     JSON.stringify(error.response, null, 4) + ", pword: ");
                return email_state.EMAIL_GOOD;
            });
    }

    validatePasswordCheck = (text) => {
        if (!text) {
            return pw_state.PASSWORD_EMPTY;
        }
        else {
            console.log('[Frontend] Signup validatePasswordCheck this.state.error[\'pword\'] ' +
                this.state.errorState['pword']);

            if (this.state.errorState['pword']) {
                return pw_state.PASSWORD_CHECK_FIRST;
            }
            else {
                if (this.state.formValues['pword'] !== text) {
                    return pw_state.PASSWORD_MISMATCH;
                }
                else {
                    return pw_state.PASSWORD_GOOD;
                }
            }

/*            if (this.state.formValues['pword'] !== text) {
                return pw_state.PASSWORD_MISMATCH;
            }
            else {
                if (!this.state.errorState['pword']) {
                    return pw_state.PASSWORD_CHECK_FIRST;
                } else {
                    return pw_state.PASSWORD_GOOD;
                }
            }*/
            // invalid = (this.state.formValues['pword'] !== value) ? 1 : 0;
        }
    }

    validateGender = (event) => {


    }

    validateInput = (event : any) => {
        // event.preventDefault();
        let {name, id, dataType, value} = event.target;
        console.log('[Frontend] Signup onBlur validateInput name: ' + name + ', id: ' + id + ', value: ' + value +
            ', dataType: ' + dataType);

        let errMsg;
        let invalid;
        if (name === emailType) {
            invalid = this.validateEmail(value, id);
            if (!invalid) {
                // invalid = email_state.EMAIL_GOOD;
                console.log("[Frontend] Signup checkExistingEmail validateEmail invalid will exit: " + !invalid);
                return;
            }

            this.setState(prevState => {
                let errorState = Object.assign({}, prevState.errorState);
                errorState[`${id}`] = Boolean(invalid);
                return { errorState };
            });

/*            if (value) {
                errMsg = invalid ? errMsgKor.email : '';
            }
            else {
                errMsg = '';
            }*/

            errMsg = emailMapping[invalid];
            console.log("[Frontend] Signup checkExistingEmail invalid: " + JSON.stringify(invalid, null, 4) +
                ", errMsg: " + errMsg);
            this.setState(prevState => {
                let errorMsg = Object.assign({}, prevState.errorMsg);
                errorMsg[`${id}`] = errMsg;
                return { errorMsg };
            });
        }
        else if (name === pwordType) {
            if (id === "pword") {
                invalid = validatePassword(value);
                this.setState(prevState => {
                    let errorState = Object.assign({}, prevState.errorState);
                    errorState[`${id}`] = Boolean(invalid);
                    return {errorState};
                });

                console.log('[Frontend] validateInput pwordType invalid: ' + invalid);

                errMsg = pwMapping[invalid];
/*                if (invalid === pw_state.PASSWORD_BASIC_BAD) {
                    errMsg = errMsgKor.password;
                } else if (invalid === pw_state.PASSWORD_CONSEC_BAD) {
                    errMsg = errMsgKor.passwordSub;
                } else if (invalid === pw_state.PASSWORD_EMPTY) {
                    errMsg = errMsgKor.passwordEmpty;
                } else {
                    errMsg = errMsgKor.passwordGood;
                }*/

                this.setState(prevState => {
                    let errorMsg = Object.assign({}, prevState.errorMsg);
                    errorMsg[`${id}`] = errMsg;
                    return {errorMsg};
                });
            }
            else {
                // invalid = this.state.formValues['pword'] !== value;
                if (!value) {
                    invalid = pw_state.PASSWORD_EMPTY;
                }
                else {
                    invalid = this.validatePasswordCheck(value);
                    // invalid = (this.state.formValues['pword'] !== value) ? 1 : 0;
                    console.log('[Frontend] Signup validateInput pwordType [pword]: ' + this.state.formValues['pword'] +
                        ', value: ' + value + ', invalid: ' + invalid);
                    // return this.state.formValues['pword'] !== value;
                    // let res = this.state.formValues['pword'] !== text;
                }

                if (invalid === 0) {
                    this.setState(prevState => {
                        let icons = Object.assign({}, prevState.icons);
                        icons[`${id}`] = <CheckCircleOutline />;
                        return { icons };
                    })
                }
                else {
                    this.setState(prevState => {
                        let icons = Object.assign({}, prevState.icons);
                        icons[`${id}`] = '';
                        return { icons };
                    })
                }

                this.setState(prevState => {
                    let errorState = Object.assign({}, prevState.errorState);
                    errorState[`${id}`] = Boolean(invalid);
                    return {errorState};
                });

                errMsg = pwMapping[invalid];
/*                if (invalid === pw_state.PASSWORD_EMPTY) {
                    errMsg = errMsgKor.passwordEmpty;
                }
                else if (invalid === pw_state.PASSWORD_BASIC_BAD) {
                    errMsg = errMsgKor.passwordCheckBad;
                }
                else {
                    errMsg = errMsgKor.passwordCheckGood;
                }*/
                this.setState(prevState => {
                    let errorMsg = Object.assign({}, prevState.errorMsg);
                    errorMsg[`${id}`] = errMsg;
                    return {errorMsg};
                });
                // errMsg = invalid ? errMsgKor.passwordCheck : '';
            }
        }
        else if (name === phoneType) {
            invalid = validateNumber(value);
            this.setState(prevState => {
                let errorState = Object.assign({}, prevState.errorState);
                errorState[`${id}`] = Boolean(invalid);
                return {errorState};
            });

            if (value) {
                errMsg = invalid ? errMsgKor.tel : '';
            } else {
                errMsg = '';
            }
            this.setState(prevState => {
                let errorMsg = Object.assign({}, prevState.errorMsg);
                errorMsg[`${id}`] = errMsg;
                return {errorMsg};
            });
        }
        else if (name === bdayType) {
            invalid = validateDate(value);
            this.setState(prevState => {
                let errorState = Object.assign({}, prevState.errorState);
                errorState[`${id}`] = Boolean(invalid);
                return { errorState };
            });

            if (value) {
                errMsg = invalid ? errMsgKor.bday : '';
            }
            else {
                errMsg = '';
            }
            this.setState(prevState => {
                let errorMsg = Object.assign({}, prevState.errorMsg);
                errorMsg[`${id}`] = errMsg;
                return { errorMsg };
            });
        }
        else if (name === genderType) {
            invalid = !value;
            let errMsg = errMsgKor.gender;
            id = name;

            this.checkRadioError(id);

/*            console.log('[Frontend] Signup onBlur last else name: ' + name + ', invalid errorstate: ' + Boolean(invalid));
            this.setState(prevState => {
                let errorState = Object.assign({}, prevState.errorState);
                errorState[`${id}`] = Boolean(invalid);
                return {errorState};
            });
            this.setState(prevState => {
                let errorMsg = Object.assign({}, prevState.errorMsg);
                errorMsg[`${id}`] = errMsg;
                return { errorMsg };
            });*/
        }
        else {
            invalid = !value;
            // console.log('[Frontend] Signup onBlur id ' + id + ', invalid? ' + invalid);
            if (id !== fieldMapping[field_state.FIELD_EMAILCONFIRM]) {

                // for child2 and child3, allow them to be empty and still be valid
                if (optionals.includes(id) && invalid) {
                    invalid = !invalid;
                }

                console.log('[Frontend] Signup onBlur last else id: ' + id + ', invalid errorstate: ' + Boolean(invalid));
                this.setState(prevState => {
                    let errorState = Object.assign({}, prevState.errorState);
                    errorState[`${id}`] = Boolean(invalid);
                    return {errorState};
                });
            }
        }

        console.log('[Frontend] Signup FindAddr handleComplete isFormFilled?: ' + this.isFormFilled());
        console.log('[Frontend] Signup onBlur email valid? ' + invalid + ', ALL this.state.errorState: ' +
            JSON.stringify(this.state.errorState, null, 4));
    }

    checkRadioError = (id) => {
        let errMsg = errMsgKor.gender;
        let value = this.state.formValues['gender'];
        let invalid = !value;
        console.log('[Frontend] Signup onBlur last else id: ' + id + ', value: ' + value + ', invalid errorstate: ' + Boolean(invalid));
        this.setState(prevState => {
            let errorState = Object.assign({}, prevState.errorState);
            errorState[`${id}`] = Boolean(invalid);
            return {errorState};
        });
        this.setState(prevState => {
            let errorMsg = Object.assign({}, prevState.errorMsg);
            errorMsg[`${id}`] = errMsg;
            return { errorMsg };
        });
    }

    handleRadioChange = (event) => {
        event.preventDefault();
        let {name, id, value} = event.target;
        console.log('[Frontend] Signup handleChange event.target id: ' + id + ', name: ' + name + ', value: ' + value);

        this.setState(prevState => {
            let formValues = Object.assign({}, prevState.formValues);
            formValues[`${name}`] = value;
            return { formValues };
        })

    }

    handleChange = (event : any) => {
        event.preventDefault();
        let {name, id, value} = event.target;
        // console.log('[Frontend] Signup handleChange event.target id: ' + id + ', name: ' + name + ', value: ' + value);
        // console.log('[Frontend] Signup handleChange email confirm: ' + fieldMapping[field_state.FIELD_EMAILCONFIRM]);

        switch (id) {
            case 'phone':
                value = insertHyphens(value);
                break;

            // case fieldMapping[field_state.FIELD_EMAILCONFIRM]:
            case 'emailConfirm':
                console.log('[Frontend] Signup handleChange event.target id: ' + id + ', name: ' + name + ', value: ' + value);

/*                this.setState(prevState => {
                    let icons = Object.assign({}, prevState.icons);
                    icons[`${id}`] = <CheckCircleOutline />;
                    return { icons };
                })*/
                break;

            default:
                break;

        }

        this.setState(prevState => {
            let formValues = Object.assign({}, prevState.formValues);  // creating copy of state variable jasper
            formValues[`${id}`] = value;                     // update the name property, assign a new value
            return { formValues };                                 // return new object jasper object
        })

        // this.validateInput(event);
    }

    fillAddress = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        // this.setState({
        //     isPostOpened : false
        // })

        if (this.mounted) {
            this.openAddrPopup(false);
            this.setPopupVisible(false);
            this.setPopupVisible(true);
        }

        let id = 'addr';
        this.setState(prevState => {
            let formValues = Object.assign({}, prevState.formValues);
            formValues[`${id}`] = fullAddress;
            return { formValues };
        })

        // InputProps={{
        //     readOnly: true,
        // }}

        this.elemRef[field_state.FIELD_ADDRDETAILS].focus();
        // console.log('[Frontend] Signup FindAddr handleComplete this.elemRef: ' +
        //     JSON.stringify(this.elemRef[field_state.FIELD_ADDRDETAILS], null, 4));
        console.log('[Frontend] Signup FindAddr handleComplete Full address: ' + fullAddress);
        // resAddress = fullAddress;
            // return fullAddress;
    }

    goSignup = () => {
        // if (1===1) {
        let finalResult = this.isFormFilled();
        if (finalResult === formFilledGood) {
            axios.post('/api/members/add',
                {
                    name: this.state.formValues.name,
                    bday: this.state.formValues.bday,
                    password: this.state.formValues.pword,
                    email: this.state.formValues.email,
                    phone: this.state.formValues.phone,
                    addr: this.state.formValues.addr,
                    addrDetails: this.state.formValues.addrDetails,
                    notes: this.state.formValues.notes,
                    child1Name: this.state.formValues.child1Name,
                    child1Bday: this.state.formValues.child1Bday,
                    child2Name: this.state.formValues.child2Name,
                    child2Bday: this.state.formValues.child2Bday,
                    child3Name: this.state.formValues.child3Name,
                    child3Bday: this.state.formValues.child3Bday,
                }
            )
            .then(res => {
                const resData = res.data;
                if (resData) {
                    console.log('[Frontend] Signup goSignup results: ' + JSON.stringify(resData, null, 4));
                    // let resultSuccess;
                    if (resData.resultMsg === Constants.success) {
                        this.setState({
                            signupMessage: resData.resultMsg,
                            signupFullName: resData.fullName,
                            signupSuccess: true,
                            redirectToLogin: Constants.urlLogin
                        });
                    }
                    else {
                        this.setState({
                            signupMessage: resData.resultMsg,
                            signupSuccess: false,
                            redirectToLogin: ''
                        });
                    }

                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    signupMessage: error.toString(),
                    signupSuccess: false
                });
            });

        }
        else {
            if (finalResult >= 0) {
                this.setState({formIncomplete: Constants.formIncomplete});
                this.elemRef[finalResult].focus();
            }
        }
    }

    updateDimensions = () => {
        let newWidth = window.innerWidth;
        let newHeight = window.innerHeight;
        console.log('[Frontend] Signup updateDimensions width: ' + newWidth + ', height: ' + newHeight);
        // const currHeight = window.innerWidth >= 600 ? window.innerHeight : 400
        this.setState({currWidth: newWidth});
        setWidth(newWidth);
        this.setState({currHeight: newHeight});
        setHeight(newHeight);
    }

    componentWillMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.mounted = false;
        console.log('[Frontend] Signup componentWillUnmount()');
        /*this.setState = (state,callback)=>{
            return;
        };*/
    }

    addNewRow = () => {
        console.log('[Frontend] Signup addNewRow');
        this.setState({
            allGeoItems: this.state.allGeoItems.concat(
                [
                    {
                        childName: '',
                        childBday: ''
                    }
                ]
            )
        });
    };

    deleteRow = () => {
        console.log('[Frontend] Signup addNewRow');
        let tempItems = this.state.allGeoItems;
        tempItems.pop();
        this.setState({
            allGeoItems: tempItems
        });
    }

    canDelete = idx => {
        let result = false;
        console.log('[Frontend] canDelete param index: ' + idx);
        if (idx > 0) {
            result = true;
        }
        return result;
        // let inputVal = evt.target.value;
    }

    render() {
        const { classes } = this.props;
        const { redirectToLogin } = this.state;
        let icon = null;
        // const classes = useStyles();

        let alertMsg = "";
        if (this.state.signupMessage) {
            if (this.state.signupSuccess) {
                alertMsg = (
                    <Alert variant="success">
                        {this.state.signupMessage}
                    </Alert>
                );
            }
            else {
                alertMsg = (
                    <Alert variant="danger">
                        {this.state.signupMessage}
                    </Alert>
                );
            }
        }

        if (redirectToLogin) {
            return <Redirect to={{
                pathname: redirectToLogin,
                state: {
                    signupMessage: this.state.signupMessage,
                    signupFullName: this.state.signupFullName
                }
            }}/>;
        }

        return (
            // <Container alignContent="center">
            <>
            <NavLinksBar/>
            <Container component="main" maxWidth={formSize}>
                {/*<CssBaseline />*/}

                {/*<div className='form-wrapper'>*/}
                {/*<DaumPostcode style={AllStyles.postCodeStyle} onComplete={this.fillAddress} />*/}
                <Paper className={classes.paper} elevation={AllStyles.paperElev} style={AllStyles.signup}>
                {/*<div className={classes.paper}>*/}
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>


                    {this.state.formValues ?
                        <div>{this.state.formValues.length}</div> : null}

{/*                    <Modal
                        open={true}
                        size={'tiny'}

                        // onOpen={() => this.toggleModalWrite(true)}
                        style={{position: 'relative'}}
                    >
                        <Modal.Header>Please fill in all fields</Modal.Header>

                        <Modal.Actions>
                            <ButtonSem
                                content="Yes I will do that"
                                labelPosition='right'
                                icon='checkmark'
                                // onClick={() => this.toggleModalWrite(false)}
                                positive
                            />
                        </Modal.Actions>
                    </Modal>*/}

                    {/*popup close and open*/}
{/*                    {this.state.isPostVisible ?
                    <Popup
                        trigger={<button className="button"> Open Modal </button>}
                        modal
                        closeOnDocumentClick
                        closeOnEscape
                        open={this.state.isPostOpened}
                        style={{width: '100px'}}
                        // onClose={this.openAddrPopup(false)}
                        // nested
                    >

                        {this.state.isPostOpened ?
                            <DaumPostcode
                                onComplete={this.fillAddress}
                                autoClose
                                width={AllStyles.postCodeStyle.width}
                                height={AllStyles.postCodeStyle.height}
                                style={AllStyles.postCodeStyle}
                            />
                            : ''}
                    </Popup> : ''}*/}

                    <form className={classes.form} noValidate>
                        <Grid container spacing={formSpacing}>
                            <Grid item xs={oneWidth*2} sm={oneWidth/2}>
                            <TextField
                                name={fields[0].titleKor}
                                style={AllStyles.fontTable}
                                variant="outlined"
                                type={fields[0].type}
                                required={fields[0].required}
                                fullWidth
                                id={fields[0].title}
                                label={fields[0].titleKor}
                                onChange={this.handleChange}
                                autoFocus
                            />
                            </Grid>
                            <Grid item xs={oneWidth*2} sm={oneWidth/2}>
                                <RadioGroup row aria-label="position" name="gender" value={this.state.formValues['gender']}
                                            id='gender' onChange={this.handleRadioChange} onBlur={this.validateInput} style={{marginLeft: '20px'}}
                                            >
                                    {/*defaultValue="top"*/}
                                    <FormControlLabel
                                        value="0"
                                        control={<Radio color="primary" />}
                                        label={Constants.signupMale}
                                        labelPlacement="bottom"
                                    />
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio color="primary" />}
                                        label={Constants.signupFemale}
                                        labelPlacement="bottom"
                                    />
                                </RadioGroup>
                            </Grid>

                            {/*key={field.title}{idx}*/}
                            {fields.map((field, idx) => (
                                idx > 0 ?
                                field.type !== btnType && field.type !== iconBtnType ?
                                <Grid item xs={oneWidth*2} sm={field.width} key={`${field.title}-${idx}`}>
                                {/*<Grid item xs={12} sm={6}>*/}
                                    <TextField
                                        disabled={field.disabled}
                                        required={field.required}
                                        style={AllStyles.fontTable}
                                        fullWidth
                                        autoComplete="fname"
                                        name={field.dataType}  // titleKor
                                        variant="outlined"
                                        type={field.type}
                                        InputLabelProps={{datatype: field.dataType}}
                                        // inputRef={el => field = el}
                                        // dataType={field.dataType}
                                        placeholder={field.placeholder}
                                        error={this.state.errorState[field.title]}
                                        helperText={this.state.errorMsg[field.title]}
                                        id={field.title}
                                        key={`${field.title}-${idx}`}
                                        label={field.titleKor}
                                        onChange={this.handleChange}
                                        onBlur={this.validateInput}
                                        InputProps={{
                                            maxLength : field.maxLen,
                                            // endAdornment: <CheckCircleOutline />
                                            endAdornment: this.state.icons[field.title]
                                            // readOnly: false
                                        }}
                                        value={this.state.formValues[field.title]}
                                        inputRef={ref => (this.elemRef[`${idx}`] = ref)}
                                        // ref={(ref) => { this.elemRef[`${idx}`] = ref; return true; }}
                                    />
                                    {/*<label htmlFor="fullName">{field.titleKor}</label>*/}
                                    {/*<input type={field.type} name={field.title} onChange={this.handleChange}/>*/}
                                </Grid> :

                                    <Grid item xs={oneWidth*2} sm={field.width} key={`${field.title}-${idx}`}>

                                        {/*{this.state.currWidth} x {this.state.currHeight}*/}
                                    {this.state.isPostVisible &&
                                        field.title === fieldMapping[field_state.FIELD_ADDRBTN] ?

                                    // {field.title === fieldMapping[field_state.FIELD_ADDRBTN] ?
                                    <Popup
                                        // disabled={field.title !== fieldMapping[field_state.FIELD_ADDRBTN]}
                                        position="top right"
                                        trigger={
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                id={field.title}
                                                required
                                                style={AllStyles.fontTable}
                                                // disabled={!this.state.buttonEnabled[`${field.title}`]}
                                                fullWidth
                                                className={classes.button}
                                                onClick={() => { this.msgConfirm(field.title) }}
                                                // startIcon={<SendIcon/>}
                                            >{field.titleKor}
                                            </Button>}
                                        closeOnDocumentClick
                                        closeOnEscape
                                        modal
                                        open={this.state.isPostOpened}
                                        contentStyle={AllStyles.popupStyle}
                                        // onClose={this.openAddrPopup(false)}
                                        nested
                                    >
                                        <DaumPostcode
                                                onComplete={this.fillAddress}
                                                autoClose
                                                width={AllStyles.postCodeStyle.width}
                                                height={AllStyles.postCodeStyle.height}
                                                style={AllStyles.postCodeStyle}/>
                                    </Popup> :

/*                                        field.type === iconBtnType ?

                                            <div>
                                            <IconButton aria-label="add" color="primary" >
                                                <AddIcon color="primary" />
                                            </IconButton>
                                            <IconButton aria-label="remove" color="primary" >
                                                <RemoveIcon color="primary" disabled={!this.canDelete(0)} />
                                            </IconButton></div>
                                            :*/

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            id={field.title}
                                            required
                                            style={AllStyles.fontTable}
                                            // disabled={!this.state.buttonEnabled[`${field.title}`]}
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => { this.msgConfirm(field.title) }}
                                            // startIcon={<SendIcon/>}
                                        >{field.titleKor}
                                        </Button>
                                    }

                                    </Grid> : null
                            ))}

{/*                            <Grid item xs={oneWidth*2}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid>*/}
                        </Grid>
                        {/*<FindAddr/>*/}

                                {/*<section>*/}
                                {/*<div style={{ display: this.state.isPostVisible ? 'block': 'none'}}>*/}
                                {/*<div style={{ visibility: this.state.isPostVisible ? 'visible': 'hidden'}}>*/}

{/*                                <button title="닫아" onClick={() => this.setState({
                                    isPostVisible : true
                                })
                                } />*/}


{/*                                {this.state.isPostOpened ?

                                    // <Popup>
                                        <DaumPostcode
                                            onComplete={this.fillAddress}
                                            autoClose
                                            width={AllStyles.postCodeStyle.width}
                                            height={AllStyles.postCodeStyle.height}
                                            style={AllStyles.postCodeStyle}
                                        />: '' }*/}

                                {/*</div>*/}
                                {/*</section>*/}

{/*                            <DaumPostcode
                                onComplete={this.fillAddress}
                                autoClose
                                width={AllStyles.postCodeStyle.width}
                                height={AllStyles.postCodeStyle.height}
                                style={AllStyles.postCodeStyle}
                            />: ''}*/}
                        <Button
                            // type="submit"
                            fullWidth
                            onClick={this.goSignup}
                            style={{height: inputHeight, ...AllStyles.fontTable}}
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {signupText}
                        </Button>
{/*                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link as={Link} to={Constants.urlLogin} variant="body2">
                                <Link href="#" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>*/}
                    </form>
                    <div style={{fontWeight: '500'}}>
                        {this.state.formIncomplete}
                    </div>
                    {alertMsg}
                    {this.state.signupMessage}
                </Paper>

                {/*<div style={{width: '100%', overflow: 'hidden'}}>*/}

                {/*debug info*/}
{/*                <div style={{width: '800px'}}>

                <div style={{width: '300px', float: 'left'}}>
                <div style={{float: 'left', width: '33%'}}>
                <h4>this.state.errorState</h4>
                {Object.keys(this.state.errorState).map((key) => (
                    <div key={key}>key: {key}, value: {this.state.errorState[key] + ''} </div>
                    ))}
                </div>

                <div style={{marginLeft: '320px'}}>
                <div style={{float: 'left', width: '33%'}}>
                    <h4>this.state.formValues</h4>
                    {Object.keys(this.state.formValues).map((key) => (
                        <div key={key}>key: {key}, value: {this.state.formValues[key] + ''} </div>
                    ))}
                </div>

                <div style={{float: 'left', width: '33%'}}>
                    <h4>this.state.isPostVisible</h4>
                    {this.state.isPostVisible + ''}
                    <br/>isFormFilled(): {this.isFormFilled() + ''}
                </div>
                </div>*/}

{/*                <Box mt={5}>
                    <Copyright />
                </Box>*/}
            </Container>
            </>
        )
    }
}

Signup.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Signup);
// export default Signup;
