import React, { Component } from 'react';
import { NavLinksBar } from '../Navbar';

import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';

// import 'bootstrap/dist/css/bootstrap.css';
// import '@fortawesome/fontawesome-free/css/all.css';


import '../Custom.css';
import {AllStyles} from "../styles/AllStyles";
import axios from "axios";
import moment from 'moment';
import Constants from "../Constants";
import SimpleImageSlider from "react-simple-image-slider";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

const monthView = 'dayGridMonth';
const weekView = 'timeGridWeek';
const dayView = 'timeGridDay';

export default class Program extends Component {

    calendarRef = React.createRef();

    cultures = ['ko-KR'];
    theme1 = 'bootstrap';
    imageWidth = 1000;
    imageLength = this.imageWidth * 9/16;

    images = [
        { url: "slides/program-1.jpg" },
        { url: "slides/program-2.jpg" },
        { url: "slides/program-3.jpg" },
        { url: "slides/program-4.jpg" },
        { url: "slides/program-5.jpg" },
        { url: "slides/program-6.jpg" },
        { url: "slides/program-7.jpg" },
        { url: "slides/program-8.jpg" },
        { url: "slides/program-9.jpg" }
    ];

    imageNames = [
        "img/program-1.jpg",
        "img/program-2.jpg",
        "img/program-3.jpg",
        "img/program-4.jpg",
        "img/program-5.jpg",
        "img/program-6.jpg",
        "img/program-7.jpg",
        "img/program-8.jpg",
        "img/program-9.jpg",
    ];

    state = {
        weekendsVisible: true,
        currentEvents: [],
        currWidth: 0,
        currHeight: 0
    }

    constructor(props) {
        super(props);
        this.getAll();
        this.updateDimensions = this.updateDimensions.bind(this);
        // console.info("[Frontend] Calendar 1 historyDepth: " + JSON.stringify(historyDepth, null, 4));
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let currWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let currHeight = typeof window !== "undefined" ? window.innerHeight : 0;
        this.setState({ currWidth, currHeight });
    }

    getAll = () => {

    }

    render() {
        // <div style={{...AllStyles.mainBody}} >
        this.imageWidth = this.state.currWidth / 1.25;
        this.imageLength = this.imageWidth * 9/16;
        return (
            <>
            <NavLinksBar/>

            <div style={{...AllStyles.center, marginTop: '40px'}}>

{/*                <AliceCarousel mouseTracking >
                    <img src={this.imageNames[0]} className="image" alt=""/>
                    <img src={this.imageNames[1]} className="image" alt=""/>
                    <img src={this.imageNames[2]} className="image" alt=""/>
                    <img src={this.imageNames[3]} className="image" alt=""/>
                    <img src={this.imageNames[4]} className="image" alt=""/>
                    <img src={this.imageNames[5]} className="image" alt=""/>
                    <img src={this.imageNames[6]} className="image" alt=""/>
                    <img src={this.imageNames[7]} className="image" alt=""/>
                    <img src={this.imageNames[8]} className="image" alt=""/>
                </AliceCarousel>*/}

                <SimpleImageSlider
                    // className="image"
                    width={this.imageWidth}
                    height={this.imageLength}
                    navStyle={2}
                    showNavs={true}
                    showBullets={true}
                    useGPURender={true}
                    images={this.images}
                />

            </div>
            </>

        )
    }
}


/*                <img
                    className="image"
                    src="img/curri-1.png"
                    width={Constants.widthSlideImage}
                    // height="206"
                    // className="d-inline-block"
                    alt="logo"/>
                <img
                    className="image"
                    src="img/curri-2.png"
                    width={Constants.widthSlideImage}
                    alt="logo"/>
                <img
                    className="image"
                    src="img/curri-3.png"
                    width={Constants.widthSlideImage}
                    alt="logo"/>
                <img
                    className="image"
                    src="img/curri-4.png"
                    width={Constants.widthSlideImage}
                    alt="logo"/>
                <img
                    className="image"
                    src="img/curri-6.png"
                    width={Constants.widthSlideImage}
                    alt="logo"/>
                <img
                    className="image"
                    src="img/curri-7.png"
                    width={Constants.widthSlideImage}
                    alt="logo"/>
                <img
                    className="image"
                    src="img/curri-8.png"
                    width={Constants.widthSlideImage}
                    alt="logo"/>*/
