import React, { Component, useRef, useEffect, useState } from 'react';

import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';


import '../Custom.css';
import '../App.css';
import {AllStyles} from "../styles/AllStyles";

import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

// mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoibWNzb25pYzciLCJhIjoiY2tienpqNDhzMWVjbDJzbGc0ZHp1ZzhxciJ9.cQ5RwSwMgo_20N5XwvxCOg';

const MapFunc = () => {
    const mapContainer = useRef();
    const [lng, setLng] = useState(-70.9);
    const [lat, setLat] = useState(42.35);
    const [zoom, setZoom] = useState(9);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom
        });

        map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
        });

        return () => map.remove();
    }, []);

    return (
        <div>
            {/*<div className="sidebar">*/}
            {/*    Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}*/}
            {/*</div>*/}
            <div className="mapContainer" ref={mapContainer} />
        </div>
    );
};

export default MapFunc;
