import React, { Component } from 'react';
import { NavLinksBar } from '../Navbar';

import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';


import '../Custom.css';
import {AllStyles} from "../styles/AllStyles";
import axios from "axios";
import moment from 'moment';
import {isMobile} from '../styles/AllStyles';

const monthView = 'dayGridMonth';
const weekView = 'timeGridWeek';
const dayView = 'timeGridDay';

export default class Intro extends Component {

    calendarRef = React.createRef();

    cultures = ['ko-KR'];
    theme1 = 'bootstrap';

    state = {
        weekendsVisible: true,
        currentEvents: []
    }

    constructor(props) {
        super(props);

        this.getAll();
        // console.info("[Frontend] Calendar 1 historyDepth: " + JSON.stringify(historyDepth, null, 4));
    }

    componentDidMount() {

    }

    getAll = () => {

    }

    render() {
        return (
            <>
                <NavLinksBar/>
                <div style={{...AllStyles.mainBody, ...AllStyles.lineHeightWide, marginTop: '0px'}}>

                    <div style={{...AllStyles.littleColumn, paddingTop: '80px'}}>
                        <img
                            src="img/director_1.jpg"
                            alt="logo"
                            width="150"
                            // height="206"
                            className="d-inline-block" />
                        <br/>&nbsp;&#10048;대표 안 혜 경
                        <br/><br/>연세대학교 아동학과 졸업
                        <br/>어린이집 원장
                    </div>

                    {!isMobile() ?
                    <div style={{...AllStyles.bigColumn, wordBreak: 'keep-all'}}><br/>
                    안녕하세요. 2代가 함께 하는 연세 유아/아동놀이교육원을 방문해 주셔서 감사합니다.<br/>
                    연세 유아/아동놀이교육원은 학부모와의 끊임없는 소통과 발달단계에 적합한 놀이교육 프로그램을 통해<br/>
                    아동들의 건강한 성장과 발달을 도모하고, 아동마다 발달을 양상과 속도가 다른 점을 고려하여<br/>
                    아동의 무한한 가능성과 잠재력이 발휘되고 실현될 수 있도록<br/>
                    유아, 아동, 학부모를 위한  다양한 교육프로그램을 진행하는 유아교육 전문기관입니다.<br/><br/>
                    우리의 미래를 책임질 아동들의 건강한 성장·발달을 위한 다양한 교육프로그램을 진행합니다.<br/>
                    아동들이 스스로 발견, 학습할 수 있도록 놀이를 통해 배움과 교육을 확장시킵니다.<br/>
                    아동의 전인발달을 위한 놀이 수업을 통해 사고력을 확장시킵니다.<br/>
                    부모의 관심과 협력을 적극 유도하기 위해 교육과정과 학부모 상담이 이루어집니다.<br/>
                    아동들의 무한한 가능성과 잠재력이 발휘되고 실현될 수 있도록 수준별 수업으로 구성되어 있습니다.<br/>
                    그룹수업의 경우 아동의 경험, 발달 수준, 흥미, 등 주제에 따라 다양하게 진행합니다.<br/>
                    아동의 효과적인 학습을 위한 1:1 Premium 수준별 개인학습이 이루어집니다.<br/>
                    주제별 통합 교육이 이루어집니다.<br/><br/>
                    아동들의 무한한 가능성과 잠재력이 발휘되고 실현될 수 있는 교육원!<br/>
                    연세유아/아동놀이교육원이 여러분과 함께 합니다!<br/>
                    감사합니다.<br/>
                    대표 안혜경<br/>
                    </div>
                        :
                        <div style={{...AllStyles.bigColumn, wordBreak: 'keep-all'}}><br/>
                            안녕하세요.<br/> 2代가 함께 하는 연세 유아/아동놀이교육원을<br/> 방문해 주셔서 감사합니다.<br/><br/>
                            연세 유아/아동놀이교육원은 학부모와의 끊임없는 소통과 발달단계에 적합한 놀이교육 프로그램을 통해
                            아동들의 건강한 성장과 발달을 도모하고, 아동마다 발달 양상과 속도가 다른 점을 고려하여
                            아동의 무한한 가능성과 잠재력이 발휘되고 실현될 수 있도록
                            유아, 아동, 학부모를 위한  다양한 교육프로그램을 진행하는 유아교육 전문기관입니다.<br/><br/>

                            아동들의 무한한 가능성과 잠재력이<br/> 발휘되고 실현될 수 있는 교육원!<br/><br/>
                            연세유아/아동놀이교육원이 <br/>여러분과 함께 합니다!<br/>
                            감사합니다.<br/><br/>
                            대표 안혜경<br/>
                        </div> }

                    <div style={{...AllStyles.littleColumn, marginTop: '20px'}}>
                        &emsp;
                    </div>

                </div>
            </>
        )
    }
}

{/*우리의 미래를 책임질 아동들의 건강한 성장·발달을 위한 다양한 교육프로그램을 진행합니다.
                            아동들이 스스로 발견, 학습할 수 있도록 놀이를 통해 배움과 교육을 확장시킵니다.
                            아동의 전인발달을 위한 놀이 수업을 통해<br/> 사고력을 확장시킵니다.
                            부모의 관심과 협력을 적극 유도하기 위해 교육과정과 학부모 상담이 이루어집니다.
                            아동들의 무한한 가능성과 잠재력이 발도휘되고 현될 수 있도록 수준별 수업으로 구성되어 있습니다.
                            그룹수업의 경우 아동의 경험, 발달 수준, 흥미, 등 주제에 따라 다양하게 진행합니다.
                            아동의 효과적인 학습을 위한 1:1 Premium 수준별 개인학습이 이루어집니다.*/}
