import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { NavLinksBar } from './Navbar';
import {Link, Redirect} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {AllStyles} from "./styles/AllStyles";
import Constants, {Utils} from "./Constants";
import axios from "axios";
import AuthenticationService from "./AuthenticationService";

const ENTER_KEY = 13;

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// const useStyles = makeStyles((theme) => ({
const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
});

class Login extends Component {

    state = {
        loginMessage: '',
        loginSuccess: true,
        formIncomplete: '',
        formValues: {
            email: '',
            password: ''
        },
        redirectUrl: ''
    };

    sampleData = '';

    constructor(props) {
        super(props);
    }

    doLogin = () => {
        let utilsClass = new Utils();

        let isEmailEmpty = utilsClass.isNullOrEmpty(this.state.formValues.email);
        let isPwEmpty = utilsClass.isNullOrEmpty(this.state.formValues.password);

        if (isEmailEmpty || isPwEmpty) {
            this.setState({
                formIncomplete: Constants.formIncomplete
            });
        }
        else {
            axios.post('/api/members/login',
                {
                    email: this.state.formValues.email,
                    password: this.state.formValues.password,
                })
                .then(res => {
                    const resData = res.data;
                    if (resData) {
                        console.log('[Frontend] Login doLogin resData: ' + JSON.stringify(resData, null, 4));
                        console.log('[Frontend] Login doLogin curr url: ' + JSON.stringify(window.location.href , null, 4));
                        // this.props.some = '';
                        AuthenticationService.registerSuccessfulLoginForJwt(this.state.formValues.email, resData.resultToken);
                        this.setState({
                            loginMessage: resData.resultMsg,
                            token: resData.resultToken,
                            loginSuccess: true,
                            redirectUrl: Constants.urlHome
                            // window.location.pathname
                            // redirectUrl: Constants.urlHome
                        });

                        // this.sampleData = 'aaa';
                        // this.props.some = '';
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loginMessage: error.toString(),
                        loginSuccess: false,
                        redirectUrl: ''
                    });
                });
        }
    }

    handleKeypress = (event) => {
        //it triggers by pressing the enter key
        if (event.keyCode === ENTER_KEY) {
            this.doLogin();
        }
    };

    handleChange = (event) => {
        event.preventDefault();
        let {name, id, value} = event.target;

        this.setState(prevState => {
            let formValues = Object.assign({}, prevState.formValues);
            formValues[`${id}`] = value;
            return { formValues };
        })
    }

    componentWillReceiveProps(nextProps) {
        console.log('[Frontend] Login componentWillReceiveProp 1 nextProps: ' + JSON.stringify(nextProps, null, 4));
        if (this.props.loginSuccess !== nextProps.loginSuccess) {
            console.log('[Frontend] Login componentWillReceiveProp nextProps: ' + JSON.stringify(nextProps, null, 4));
        }
/*        if (nextProps.location !== this.props.location) {
            console.log('[Frontend] Login componentWillReceiveProps url: ' + JSON.stringify(this.props.location, null, 4));
            this.setState({ redirectUrl: this.props.location })
        }*/
        // this.props.some = '';
        // this.updateLoggedIn();
    }

    componentWillUpdate() {
        console.log('[Frontend] Login componentWillUpdate');
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log('[Frontend] Login shouldComponentUpdate: ' + JSON.stringify(nextState.redirectUrl, null, 4));
        if (nextState.redirectUrl !== this.state.redirectUrl) {
            // nextProps.some = '';
        }
        return true;
    }


    render() {
        // const classes = useStyles();
        const {classes} = this.props;
        let successMsg = '';
        let fullName = '';
        const { loginSuccess } = this.state;

        console.log ("ALL signup: " + JSON.stringify(this.props, null, 4));

        if (this.props && this.props.location && this.props.location.state) {
            console.log ("ALL signup: " + JSON.stringify(this.props.location.state, null, 4));
            successMsg = this.props.location.state.signupMessage;
            fullName = this.props.location.state.signupFullName;
        }

        if (this.state.redirectUrl) {
            console.log('[Frontend] Login doLogin redirectUrl: ' + JSON.stringify(this.state.redirectUrl, null, 4));
            return <Redirect to={{
                pathname: this.state.redirectUrl,
                state: {
                    signupMessage: this.state.signupMessage,
                    signupFullName: this.state.signupFullName
                }
            }}/>;
        }

        return (
            <>
            <NavLinksBar/>
            <div style={AllStyles.mainLogin}>
                {successMsg ?
                    <div style={{...AllStyles.centerText, ...AllStyles.mainBody, fontSize: '26px'}}>
                        {fullName}님 {Constants.successMsgLogin}
                    </div>
                    : '' }
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {Constants.loginTitle}
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={Constants.loginId}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={this.handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={Constants.loginPw}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeypress}
                            />
                            {/*                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />*/}
                            <Button
                                // type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={AllStyles.loginButton}
                                className={classes.submit}
                                onClick={() => { this.doLogin() }}
                            >
                                {Constants.loginButton}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    {/*                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>*/}
                                </Grid>
                                <Grid item>
                                    <div style={{fontSize: "16px"}}>
                                        <Link as={Link} to={Constants.urlSignup} variant="body1">
                                            {/*<Link href="#" variant="body1">*/}
                                            {Constants.goSignup}
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    {/*            <Box mt={8}>
                <Copyright />
            </Box>*/}

                    {!this.state.loginSuccess && <div className="alert alert-warning">{Constants.loginFail}</div>}
                </Container>
            </div>
            </>
        );
    }


}

/*export default function Login() {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
{/!*                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />*!/}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
{/!*            <Box mt={8}>
                <Copyright />
            </Box>*!/}
        </Container>
    );
}*/

export default withStyles(useStyles)(Login);
