import React, {Component} from 'react';
import moment from 'moment';
import {AllStyles, getAddrWidth} from "../styles/AllStyles";
import DaumPostcode from "react-daum-postcode";

const FindAddr = () => {

    const postCodeStyle = {
        display: "block",
        position: "absolute",
        top: "50px",
        zIndex: "100",
        padding: "7px"
    }

    const width = 595;
    const height = 450;

    let resAddress = '';

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        console.log('[Frontend] Signup FindAddr handleComplete Full address: ' + fullAddress);
        resAddress = fullAddress;
        // return fullAddress;
    }

    return (
        <>
        <DaumPostcode
            onComplete={handleComplete}
            autoClose
            width={getAddrWidth()}
            height={height}
            style={AllStyles.postCodeStyle}
            // height={700}
        />
            AA{resAddress}
        </>
    )
}

export default FindAddr;