import React, { Component } from 'react';

import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';


import '../Custom.css';
import {AllStyles} from "../styles/AllStyles";
import axios from "axios";
import moment from 'moment';

const monthView = 'dayGridMonth';
const weekView = 'timeGridWeek';
const dayView = 'timeGridDay';

export default class Elem extends Component {

    calendarRef = React.createRef();

    cultures = ['ko-KR'];
    theme1 = 'bootstrap';

    state = {
        weekendsVisible: true,
        currentEvents: []
    }

    constructor(props) {
        super(props);

        this.getAll();
        // console.info("[Frontend] Calendar 1 historyDepth: " + JSON.stringify(historyDepth, null, 4));
    }

    componentDidMount() {

    }

    getAll = () => {

    }

    render() {
        return (
            <div className='demo-app'>
                {/*{this.renderSidebar()}*/}
                <div className='demo-app-main' style={AllStyles.mainBody}>


                </div>
            </div>
        )
    }
}
