import {AllStyles} from "./styles/AllStyles";
import React from "react";

const Constants = {
    urlHome             :   '/',
    url1Intro           :   '/intro',
    url1Abouts          :   '/abouts',
    url1Greetings       :   '/greetings',
    url1Ceo             :   '/ceo',
    url2Teachers        :   '/teachers',
    url3Prog            :   '/program',
    url3ProgInfant      :   '/infants',
    url3ProgKinder      :   '/kinder',
    url3ProElem         :   '/elem',
    url3_5Activity      :   '/activity',
    url4TimeTable       :   '/timetable',
    url5Register        :   '/register',
    url6Location        :   '/location',
    url6Announce        :   '/announce',
    urlSignup           :   '/signup',
    urlLogin            :   '/login',
    urlLoginFunc        :   '/loginFunc',
    urlMyInfo           :   '/myInfo',
    urlLostPassword     :   '/lostPassword',
    urlResetPassword    :   '/resetPassword',
    url100Visitors      :   '/visitors',
    urlAdmin            :   '/admin',
    urlAdminAnnounce    :   '/adminAnnounce',
    urlMallHome         :   '/mall',
    urlExchange         :   '/exchange',

    greenbowMain        :   '그린보우부설 연세유아/아동놀이교육원',
    descPlaceholder     :   '이미지 위 표시 Text',
    deleteText          :   'Yes Delete',
    deleteMultiple      :   'Delete multiple files?',
    watermarkMsg        :   'Watermark 생성',

    menuMain            :   '연세유아/아동놀이교육원',
    menuMainEng         :   'Yonsei Childhood Education Center',
    menuLogin           :   '로그인',
    menuLogout          :   '로그아웃',
    menuSignup          :   '회원가입',
    menuMyAcct          :   '나의 계정',
    menuMembers         :   '멤버십',
    menuMyInfo          :   '나의 정보',
    menuMyInfoView      :   '정보 확인',
    menuMyReserve       :   '나의 예약',
    menuAdminUpload     :   'Admin',
    menuAdminActivity   :   '활동사진',
    menuAdminAnnounce   :   '공지사항',
    signupMale          :   '남성',
    signupFemale        :   '여성',
    bday                :   '생년월일',
    phone               :   '전화번호',

    loginName           :   '이름',
    loginId             :   'Email 주소',
    loginPw             :   '패스워드',
    loginSuccess        :   '환영합니다!',
    loginFail           :   '이메일 또는 암호가 잘못되었습니다',
    lostPasswordFailMsg :   '이름, 이메일 또는 전화번호가 잘못되었습니다',
    lostPasswordInit    :   -1,
    lostPasswordSuccess :   1,
    lostPasswordFail    :   0,
    formIncomplete      :   '모든 필수 입력칸을 채워주세요',
    goSignup            :   '회원가입',
    officeAddr          :   '서울특별시 마포구 서강로 137-7, 신촌 르메이에르타운 1차 406호 (본원)',
    officeAddrM1        :   '서울특별시 마포구 서강로 137-7,',
    officeAddrM2        :   '신촌 르메이에르타운 1차 406호 (본원)',
    officeEmail         :   'Email: ysuanori@gmail.com', // ceo@uanori.com
    officePhone         :   'Office: 010-4533-5553', // 02-6385-6600
    officeAddr2         :   '서울시 영등포구 여의동로3길 10 (여의도 자이)',
    officeAddr2M1       :   '서울시 영등포구 여의동로3길 10',
    officeAddr3         :   '서울특별시 서초구 서초동 (오픈예정)',
    saveImageOrder      :   '이미지 순서 저장',

    evtDate             :   '날짜 선택',
    evtStartTime        :   '시작 시간 선택',
    evtEndTime          :   '종료 시간 선택',
    timeMillis          :   1000,
    startTime           :   '시작시간',
    endTime             :   '종료시간',
    evtPlaceHolder      :   '이벤트 입력',
    evtRegister         :   '등록',

    imgPreviewWidth     :   700,
    imgPreviewHeight    :   380,

    textContacts        :   '연락처',
    textDirections      :   '찾아오시는 길',
    widthSlideImage     :   1200,
    loginTitle          :   'Log in',
    loginButton         :   '로그인',
    lostPasswordButton  :   '이메일 발송',
    lostPasswordTitle   :   '비밀번호 초기화',
    resetPasswordTitle  :   '비밀번호 재설정',
    resetPasswordButton :   '재설정',
    announcements       :   '공지사항',
    success             :   'success',
    fail                :   'fail',
    successMsgLogin     :   '회원가입을 축하합니다!',
    email               :   'email',
    password            :   'password',
    // loginGuideText      :   '이미 계정이 '
    ROLE_USER           :   0,
    ROLE_PM             :   1,
    ROLE_ADMIN          :   2,

    uploadImgWidth      : 200,
    uploadImgMargin     : '100px'
}

const errMsgKor = {
    tel: '번호만 입력 가능합니다',
    phoneGood: '사용 가능한 전화번호 입니다',
    email: '이메일 형식을 다시 확인하세요',
    emailGood: '사용 가능한 이메일 주소입니다',
    bday: '주민등록번호 6자리 (생년월일) 형식을 다시 확인하세요',
    bdayGood: '',
    passwordEmpty: '',
    password: '8~16자 길이, 영문 + 숫자 + 특수문자 조합',
    passwordSub: '동일 또는 연속 문자 3회 금지 (aaa,123)',
    passwordGood: '비밀번호 OK!',
    passwordCheckBad: '비밀번호가 일치하지 않습니다',
    passwordCheckGood: '비밀번호가 일치합니다!',
};

class Utils {

    isNullOrEmpty = (value) => {
        return !(typeof value === "string" && value.length > 0);
    }

    validateNumber = (text) => {
        let value = text.split('-').join('');
        if (!value) {
            return true;
        } else {
            return isNaN(value);
        }
    }

    removeHyphens = (text) => {
        let value = text.split('-').join('');
        return value;
    }

    insertHyphens = (text) => {
        var result = [];
        let noDashes = text.split('-').join('');
        let finalStr = '';
        // console.log('[Frontend] Signup insertHyphens() noDashes: ' + noDashes);

        for (let i=0; i<noDashes.length; i++) {
            if (i===3 || i===7) {
                finalStr = finalStr + '-';
                // noDashes = noDashes + '-';
                // console.log('[Frontend] Signup insertHyphens() noDashes: ' + noDashes);
            }
            {
                finalStr = finalStr + noDashes.charAt(i);
            }
        }

        console.log('[Frontend] Signup insertHyphens() final: ' + finalStr);
        return finalStr;
        /*    text = text.replace(/^\d{2}-?\d{3}-?\d{3}-?\d{3}$/, "");
            while (text.length >= 6){
                result.push(text.substring(0, 3));
                text = text.substring(3);
            }
            if (text.length > 0) result.push(text);
            return result.join("-");*/
    }
}
export default Constants
export { Utils, errMsgKor };
