import React, { Component } from 'react';
import { NavLinksBar } from '../Navbar';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import {Comment, Confirm, Dropdown, Form, Header, Icon} from 'semantic-ui-react';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction'

import googleCalendarPlugin from '@fullcalendar/google-calendar';
import koLocale from '@fullcalendar/core/locales/ko';
import {isMobile} from '../styles/AllStyles';
import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';
import { getHourRange, getHourRangeEnd, getHourRange24, convertReadable, convertToHours, schedHours } from './Common';
import Modal from 'react-bootstrap/Modal'
// import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css'; // needs additional webpack config!
import bootstrapPlugin from '@fullcalendar/bootstrap';
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import '../Custom.css';
import {AllStyles} from "../styles/AllStyles";
import {errMsgKor, Utils} from '../Constants';
import axios from "axios";
import moment from 'moment';
// import 'moment/locale/ko'
import Grid from "@material-ui/core/Grid";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import AuthenticationService from "../AuthenticationService";
// import {Input} from "reactstrap";
// const [ locationKeys, setLocationKeys ] = useState([]);
// const history = useHistory();

// import globalize from 'globalize';

// require('globalize/lib/cultures/globalize.culture.ko-KR.js');

// const history = useHistory();

// const routeChange = () => {
//     history.goBack()
// };
let fieldCounter=0;
var field_state = {
    FIELD_NAME: fieldCounter++,
    FIELD_PHONE: fieldCounter++,
    FIELD_EMAIL: fieldCounter++,
    FIELD_RESERVE: fieldCounter++,
};

let emailCounter=0;
var email_state = {
    EMAIL_GOOD: emailCounter++,
    EMAIL_EMPTY: emailCounter++,
    EMAIL_BASIC_BAD: emailCounter++,
    EMAIL_EXISTS: emailCounter++,
};

let emailMapping = {};
emailMapping[email_state.EMAIL_GOOD] = '사용 가능한 이메일 주소입니다';
emailMapping[email_state.EMAIL_EMPTY] = '';
emailMapping[email_state.EMAIL_BASIC_BAD] = '이메일 형식을 다시 확인하세요';
emailMapping[email_state.EMAIL_EXISTS] = '이미 사용중입니다';

let fieldMapping = {};
fieldMapping[field_state.FIELD_NAME] = 'name';
fieldMapping[field_state.FIELD_PHONE] = 'phone';
fieldMapping[field_state.FIELD_EMAIL] = 'email';
fieldMapping[field_state.FIELD_RESERVE] = 'reserve';

const emailType = 'email';
const phoneType = 'tel';
const firstMarginLeft = '40px';
const marginLeft = '15px';
const marginTop = '10px';
const marginBottom = '15px';
const oneWidth = '100px';
const twoWidths = '150px';
const threeWidths = '430px';

let fields = [];


const schedGuide = '수업이 있는 날에 클릭하여 예약할 수 있습니다';
const monthView = 'dayGridMonth';
const weekView = 'timeGridWeek';
const dayView = 'timeGridDay';
const reserveText = '예약할 프로그램 및 성함 입력';
const slotDuration = '1:00:00';
const slotMinTime = '10:00:00';
const slotMaxTime = '18:00:00';
const allDaySlot = false;
let hourOptions = [];
let savedSched = false;
let selectedHour = '3 PM ~ 4 PM';  // init
const newSchedTitle = '예약';
const newFullNameGuide = '예약자';
const newPhoneNumber = '휴대폰 번호';
const newEmail = '이메일 주소';
const newSchedGuide = '예약 입력';
const hourGuide = '시간대';
const closeButton = '취소';
const saveButton = '신청';

const saveMode = 0;
const updateMode = 1;
const hideSched = 0;
const showSched = 1;

const historyDepth = {
    initial: undefined,
    current: undefined
};


/*useEffect(() => {
  return history.listen(location => {
    if (history.action === 'PUSH') {
      setLocationKeys([ location.key ])
    }

    if (history.action === 'POP') {
      if (locationKeys[1] === location.key) {
        setLocationKeys(([ _, ...keys ]) => keys)

        // Handle forward event

      } else {
        setLocationKeys((keys) => [ location.key, ...keys ])

        // Handle back event

      }
    }
  })
}, [ locationKeys, ])*/

export default class TimeTable extends Component {

    calendarRef = React.createRef();

    cultures = ['ko-KR'];
    theme1 = 'bootstrap';

    state = {
        currentEvents: []
    }

    constructor(props) {
        super(props);
        this.modalInput = React.createRef();
        this.elemRef = [];
        this.state = {
            openModal: false,
            openFinalAlert: false,
            schedInput: '',
            mydate: '',
            selectedDate:'',
            selectedHours: '',
            startStr: '',
            endStr: '',
            savedHours: '',
            calData: '',
            isSave: 0, // default mode 0 is save, 1 is update
/*            formValues: {
                name: '',
                phone: '',
                email: '',
                reserve: ''
            },
            errorState: {
                name: false,
                phone: false,
                email: false,
                reserve: false
            },
            errorMsg: {
                name: '',
                phone: '',
                email: '',
                reserve: ''
            },*/
            weekendsVisible: true
        }
        // history.go(1);
        // props.history.push('/');
        // props.history.push(this.props.match.url);

        let isLoggedIn = AuthenticationService.isUserLoggedIn();

        if (isLoggedIn) {
            fields =  [
                {title: fieldMapping[field_state.FIELD_RESERVE], titleKor: '예약내역 (Optional)', type: 'text', dataType: 'text', width: threeWidths,
                    required: false, marginLeft: marginLeft, marginBottom: marginBottom}
            ];
            this.state = {
                formValues: {
                    reserve: ''
                },
                errorState: {
                    reserve: false
                },
                errorMsg: {
                    reserve: ''
                },
            }
        }
        else {
            fields =  [
                {title: fieldMapping[field_state.FIELD_NAME], titleKor: '이름', type: 'text', dataType: 'text', width: oneWidth,
                    required: true, marginLeft: firstMarginLeft, marginBottom: marginBottom},
                {title: fieldMapping[field_state.FIELD_PHONE], titleKor: '휴대폰번호', type: 'tel', dataType: 'tel', width: twoWidths,
                    required: true, marginLeft: marginLeft, marginBottom: marginBottom},
                {title: fieldMapping[field_state.FIELD_EMAIL], titleKor: '이메일주소', type: 'email', dataType: 'email', width: threeWidths,
                    required: true, marginLeft: marginLeft, marginBottom: marginBottom},
                {title: fieldMapping[field_state.FIELD_RESERVE], titleKor: '예약내역 (Optional)', type: 'text', dataType: 'text', width: threeWidths,
                    required: false, marginLeft: marginLeft, marginBottom: marginBottom}
            ];
            this.state = {
                formValues: {
                    name: '',
                    phone: '',
                    email: '',
                    reserve: ''
                },
                errorState: {
                    name: false,
                    phone: false,
                    email: false,
                    reserve: false
                },
                errorMsg: {
                    name: '',
                    phone: '',
                    email: '',
                    reserve: ''
                },
            }
        }

        this.getAll();
        let hourText = '';
        hourOptions = [];
        for (let i=schedHours.minTime; i<schedHours.maxTime; i++) {
            hourText = convertReadable(i) + ' ~ ' + convertReadable(i+1);
            hourOptions.push({key: hourText, value: hourText, text: hourText});
        }
        historyDepth.initial = window.history.length;
        historyDepth.current = window.history.length;
        console.info("[Frontend] Calendar 1 historyDepth: " + JSON.stringify(historyDepth, null, 4));
    }

    componentDidMount() {
        let calendarApi = this.calendarRef.current.getApi();
        // calendarApi.setOption('width', AllStyles.calendarWidth);
        // calendarApi.setOption('height', AllStyles.calendarHeight);
    }

    onSelectedHours = (event, data) => {
        let inputText = data.text;
        console.log("[Frontend] TimeTable onSelectedHours: " + JSON.stringify(data));

        let inputValue = data.value;
        let hoursStr = convertToHours(inputValue);
        let startTime = convertReadable(hoursStr[0], true);
        let endTime = convertReadable(hoursStr[1], true);

        let currDate = moment(this.state.calData.start);
        currDate.set({hour: startTime});
        this.state.calData.start = currDate.toISOString();
        currDate.set({hour: endTime});
        this.state.calData.end = currDate.toISOString();

        this.setState({
            selectedHours: inputValue,
            startStr: startTime,
            endStr: endTime
        });
    }

    onFullName = (event, data) => {
        let inputValue = event.target.value;
        this.setState({
            fullName: inputValue
        });
    }

    onPhoneNumber = (event, data) => {
        let inputValue = event.target.value;
        this.setState({
            phoneNumber: inputValue
        });
    }

    onSchedInput = (event, data) => {
        let inputValue = event.target.value;
        this.setState({
            schedInput: inputValue
        });
    }

    onEnteredModal = (event, data) => {
        console.log('[Frontend] Calendar onEnteredModal modal focus');
        // this.modalInput.current.focus();
        this.elemRef[0].focus();
    }

    onExitModal = (event, data) => {
        // let inputValue = data.value;
        if (!savedSched) {
            this.setState({
                selectedHours: '',
                schedInput: ''
            });
        }
        savedSched = false;
    }

    onCancelSched = (event, data) => {
        // let inputValue = data.value;
        // if (!savedSched) {
        this.setState({
            selectedHours: '',
            schedInput: ''
        });
        // }
        this.onCloseModal();
    }

    onSaveSched = (event, data) => {
        // let inputValue = data.value;
        // console.log("data: " + JSON.stringify(event, null, 4));
        // this.setState({
        //     openModal: inputValue
        // });
        savedSched = true;
        if (this.isFormFilled()) {
            this.onCloseModal();

            this.addOrModify(this.state.isSave, hideSched);
        }
    }

    onCloseModal = () => {
        this.setState({
            openModal: false,
            openFinalAlert: true
        });
    }

    /*  componentDidMount() {
        const { history } = this.props;

        window.addEventListener("popstate", () => {
          console.log('[Frontend] Calendar 1 componentDidMount');
          // history.goBack();
          this.props.history.goBack();
        });
      }*/

    routerWillLeave(nextState) { // return false to block navigation, true to allow
        if (nextState.action === 'POP') {
            // handle "Back" button clicks here
        }
    }

    /*  componentDidUpdate() {
        console.log('[Frontend] Calendar 1 componentDidUpdate');
        window.onpopstate = e => {
          console.log('[Frontend] Calendar 1 back button');
        }
      }*/

    // componentDidUnmount() {
    //   window.onpopstate = () => {}
    // }

    validateEmail = (text, id) => {
        const reg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;
        if (!text) {
            return true;
        }
        else {
            let result;
            let emailRegex = reg.test(text);
            if (emailRegex) {
                result = email_state.EMAIL_GOOD;;
            }
            else {
                result = email_state.EMAIL_BASIC_BAD;;
            }
            console.log("[Frontend] Signup checkExistingEmail validateEmail result: " + JSON.stringify(result, null, 4));
            return result;
        }
    }

    validateInput = (event : any) => {
        event.preventDefault();
        let {name, id, dataType, value} = event.target;
        console.log('[Frontend] Signup onBlur validateInput name: ' + name + ', id: ' + id + ', value: ' + value +
            ', dataType: ' + dataType);

        let errMsg;
        let invalid;

        let isLoggedIn = AuthenticationService.isUserLoggedIn();
        if (isLoggedIn) {
            invalid = !value;
            // console.log('[Frontend] Signup onBlur id ' + id + ', invalid? ' + invalid);
            if (id !== fieldMapping[field_state.FIELD_RESERVE]) {
                this.setState(prevState => {
                    let errorState = Object.assign({}, prevState.errorState);
                    errorState[`${id}`] = Boolean(invalid);
                    return {errorState};
                });
            }
        }
        else {
            if (name === emailType) {
                invalid = this.validateEmail(value, id);
                this.setState(prevState => {
                    let errorState = Object.assign({}, prevState.errorState);
                    errorState[`${id}`] = Boolean(invalid);
                    return {errorState};
                });

                errMsg = emailMapping[invalid];
                console.log("[Frontend] Signup checkExistingEmail invalid: " + JSON.stringify(invalid, null, 4) +
                    ", errMsg: " + errMsg);
                this.setState(prevState => {
                    let errorMsg = Object.assign({}, prevState.errorMsg);
                    errorMsg[`${id}`] = errMsg;
                    return {errorMsg};
                });
            } else if (name === phoneType) {
                // Utils utils = new Utils();
                invalid = new Utils().validateNumber(value);
                this.setState(prevState => {
                    let errorState = Object.assign({}, prevState.errorState);
                    errorState[`${id}`] = Boolean(invalid);
                    return {errorState};
                });

                if (value) {
                    errMsg = invalid ? errMsgKor.tel : '';
                } else {
                    errMsg = '';
                }
                this.setState(prevState => {
                    let errorMsg = Object.assign({}, prevState.errorMsg);
                    errorMsg[`${id}`] = errMsg;
                    return {errorMsg};
                });
            } else {
                invalid = !value;
                // console.log('[Frontend] Signup onBlur id ' + id + ', invalid? ' + invalid);
                if (id !== fieldMapping[field_state.FIELD_RESERVE]) {
                    this.setState(prevState => {
                        let errorState = Object.assign({}, prevState.errorState);
                        errorState[`${id}`] = Boolean(invalid);
                        return {errorState};
                    });
                }
            }
        }

        console.log('[Frontend] Signup FindAddr handleComplete isFormFilled?: ' + this.isFormFilled());
        console.log('[Frontend] Signup onBlur email valid? ' + invalid + ', ALL this.state.errorState: ' +
            JSON.stringify(this.state.errorState, null, 4));
    }

    isFormFilled = () => {
        let isFilled = true;
        Object.keys(this.state.formValues).map((key) => {
            if (this.state.formValues[key] === '' && key !== fieldMapping[field_state.FIELD_RESERVE]) {
                isFilled = false;
            }
            // console.log('[Frontend] Signup isFormFilled formValues key: ' + key + ', value: ' +
            //     this.state.formValues[key]);
        });

        console.log('[Frontend] Signup isFormFilled errorState: ' +
            JSON.stringify(this.state.errorState, null, 4));

        Object.keys(this.state.errorState).map((key) => {
            if (this.state.errorState[key] === true) {
                isFilled = false;
            }
            // console.log('[Frontend] Signup isFormFilled errorState key: ' + key + ', value: ' +
            //     this.state.formValues[key]);
        });

        console.log('[Frontend] Signup isFormFilled final result isFilled: ' + isFilled);
        return isFilled;
    }

    addOrModify = (nType, bShow) => {
        let apiType = 'calendar';
        let apiPrefix = '/api/';
        let apiName = '';
        let isLoggedIn = AuthenticationService.isUserLoggedIn();
        // calendar/add';
        if (bShow === hideSched) {
            apiType = 'reserve';
        }
        if (nType === 0) {
            let addType = '/add';
            if (isLoggedIn) {
                addType = '/addSecure';
            }
            apiName = apiPrefix + apiType + addType;
        }
        else {
            apiName = apiPrefix + apiType + '/mod';
            // apiName = '/api/calendar/mod';
        }

        let calData = this.state.calData;
        console.log("[Frontend] Calendar 1 addOrModify type: " + nType + ", calData: " + JSON.stringify(calData, null, 4));

        const newStart = moment(calData.start).format('YYYY-MM-DD HH:mm:ss');
        const newEnd = moment(calData.end).format('YYYY-MM-DD HH:mm:ss');

        let dbData = {
            id: calData.id,
            title: calData.title,
            start: calData.start,
            end: calData.end,
            allDay: calData.allDay
        };

        let allData = '';

        if (bShow === showSched) {
            allData = {
                title: calData.title,
                course: '',
                start: calData.start,
                end: calData.end,
                allDay: calData.allDay
            }
        }
        else {
            if (isLoggedIn) {
                allData = {
                    title: calData.title,
                    course: '',
                    start: calData.start,
                    end: calData.end,
                    allDay: calData.allDay,
                    reserve: this.state.formValues.reserve
                }
            }
            else {
                allData = {
                    title: calData.title,
                    course: '',
                    start: calData.start,
                    end: calData.end,
                    allDay: calData.allDay,
                    name: this.state.formValues.name,
                    phone: this.state.formValues.phone,
                    email: this.state.formValues.email,
                    reserve: this.state.formValues.reserve
                }
            }
        }

        // axios.post(apiName, {
        //     dbData
        // })
        calData.title = this.state.schedInput;
        console.log("[Frontend] Calendar 1 addOrModify selectedHours: " + this.state.selectedHours + ', all: ' +
            JSON.stringify(allData, null, 4));
        // calData. = this.state.selectedHours;
        if (nType === 0) {
            axios.post(apiName, allData
/*                title: calData.title,
                start: calData.start,
                end: calData.end,
                allDay: calData.allDay*/
            )
            .then(res => {
                // this.state.commentsList = res.data;
                let addId = 0;
                if (res && res.data) {
                    addId = res.data.id;
                }

                if (bShow === showSched) {
                    let calendarApi = this.calendarRef.current.getApi();
                    calendarApi.addEvent({
                        id: addId,
                        title: calData.title,
                        start: calData.start,
                        end: calData.end,
                        allDay: calData.allDay,
                        backgroundColor: '#EBF5FB',
                        borderColor: '#EBF5FB',
                        textColor: '#008080'
                    });
                    this.getAll();
                }
                console.log("[Frontend] Calendar 1 addOrModify type: " + nType + ", res.data: " +
                    JSON.stringify(res.data, null, 4));
            })
            .catch(error => {
                console.log("Post Error: " + error);
            });
        }
        else {
            console.log("[Frontend] Calendar 1 mod type: " + nType + ", calData: " + JSON.stringify(calData, null, 4));
            axios.post(apiName, {
                id: calData.id,
                title: calData.title,
                start: calData.start,
                end: calData.end,
                allDay: calData.allDay
            })
            .then(res => {
                this.getAll();
            })
            .catch(error => {
                console.log("Post Error: " + error);
            });
        }
        this.setState({
            schedInput: ''
        });
    }

    getAll = () => {
        axios.get('/api/course/list/') // calendar
            .then(res => {
                console.log("[Frontend] Calendar 1 list From /api/calendar/list: " + JSON.stringify(res.data));
                // this.setState({ commentsList });
                if (res.data) {
                    let calendarApi = this.calendarRef.current.getApi();
                    calendarApi.removeAllEvents();
                    for (const calData of res.data) {
                        calendarApi.addEvent({
                            id: calData.id,
                            title: calData.title,
                            start: calData.start,
                            end: calData.end,
                            allDay: calData.allDay,
                            canReserve: calData.canReserve
                        });
                    }

                    // this.setState({campaignHistoryList: res.data});
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    changeReserve(e) {
        this.setState({ reserveInput: e.target.value });
    }

    showModal = (flag) => {
        console.log("[Frontend] Comments showModal: " + flag);
        this.setState({open: flag})
        if (flag === false) {
            this.focusName();
        }
    }

    toggleModal(delId, bSave) {
        console.log("[Frontend] Comments toggleModal delId: " + delId);
        this.setState({
            modal: !this.state.modal
        });
        this.setState({
            openModal: true,
            isSave: bSave
            // delId: delId
        });
    }

    getSeverMonth(index) {
        let calendarApi = this.calendarRef.current.getApi();
        let currDate = calendarApi.getDate();
        let selectedMonth = currDate.getMonth()+1;

        let currMonth = 0;
        let selectedStr = JSON.stringify(selectedMonth, null, 4);

        let newDate = new Date();
        currMonth = newDate.getMonth() + 1;
        axios.get('/api/calendar/getMonth')
            .then(res => {
                const resData = res.data;
                if (resData != null && resData >= 0) {
                    currMonth = resData.month;
                }
                let currStr = JSON.stringify(currMonth, null, 4);
                console.log("[Frontend] TimeTable selectedMonth: " + selectedStr + ", currentMonth: " + currStr);
                if (index == 1) {
                    calendarApi.next();
                }
                else {
                    if (selectedMonth >= currMonth) {
                        calendarApi.prev();
                    }
                }
            })
            .catch(error => {
                let currStr = JSON.stringify(currMonth, null, 4);
                console.log(error);
                console.log("[Frontend] TimeTable selectedMonth: " + selectedStr + ", currentMonth: " + currStr);
                if (index == 1) {
                    calendarApi.next();
                }
                else {
                    calendarApi.prev();
                }
            });
    }

    monthChange(index) {
        let currMonth = this.getSeverMonth(index);
    }

    render() {
        // text: String.fromCharCode(8678),
        // icon: "right-single-arrow",
        // console.log('[TimeTable] render() calData.start: ' + this.state.calData.start);
        return (
            <>
            <NavLinksBar/>

                {/*{this.state.openFinalAlert ? alert('예약이 정상적으로 접수되었으며 예약정보는 이메일로 발송드립니다.') : ''}*/}

            <div className='demo-app' style={{...AllStyles.mainBody, ...AllStyles.centerAll, marginTop: '10px'}}>
                {/*Start: {this.state.startStr}, End: {this.state.endStr}*/}
                {/*{this.renderSidebar()}*/}
                {/*<div className='demo-app-main' style={AllStyles.mainBody}>*/}

                    <FullCalendar ref={this.calendarRef}
                        themeSystem={this.theme1}
                        plugins={[bootstrapPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin, googleCalendarPlugin]}
                        googleCalendarApiKey={'AIzaSyD-T6Jj8mAPWnxiE9EmDAOtnG3bHRtH0zs'}
                        customButtons={{
                          prevCustButton: {
                              // icon: " fa fa-times",
                              icon: " left-single-arrow",
                              text: "<<",
                              click: () => {
                                this.monthChange(0)
                              }
                          },
                          nextCustButton: {
                              text: ">>",
                              icon: "right-single-arrow",
                              click: () => {
                                this.monthChange(1)
                              }
                          }
                        }}
                        headerToolbar={{
                            left: 'today',
                            // left: 'prev,next  prevCustButton nextCustButton today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        initialView='dayGridMonth'
                        locale={koLocale}
                        // culture={this.cultures[0]}
                        editable={true}
                        expandRows={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={this.state.weekendsVisible}
                        // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                        select={this.handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        eventClick={this.handleEventClick}
                        eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                        // eventResize={this.handleResize}
                        // eventChange={this.handleChange}
                        eventDragStop={this.handleDrag}
                        slotDuration={slotDuration}
                        slotMinTime={slotMinTime}
                        slotMaxTime={slotMaxTime}
                        allDaySlot={allDaySlot}
                        height={'100%'}
                        displayEventTime={false}
                        // eventSources={[
                        // eventChange={this.monthChange}
                        // dateSet
                        dateSet={(args) => console.log("###datesSet:", args)}


                        // events={
                            // {googleCalendarId: 'ko.south_korea#holiday@group.v.calendar.google.com'}
                            // {googleCalendarId: 'ee1pbq0hul701ge2kqgad7ammc@group.calendar.google.com'}
                        // }
                        // ''}

                        //  {googleCalendarId: 'ko.south_korea#holiday@group.v.calendar.google.com'}

                        // eventSources={{
                        //     googleCalendarId : "ko.south_korea#holiday@group.v.calendar.google.com",
                        //     className : "koHolidays",
                        //     color : "#FF0000",
                        //     textColor : "#FFFFFF"
                        // }}

                        // eventColor={'#800020'}

                        // datesRender={this.datesRender}
                        /* you can update a remote database when these fire:
                        eventAdd={function(){}}
                        eventChange={function(){}}
                        eventRemove={function(){}}
                        */
                    />
                {/*</div>*/}

                <Modal show={this.state.openModal} onHide={this.onCloseModal.bind(this)}
                       // dialogClassName="modal-90w"
                       autoFocus={true} onEntered={this.onEnteredModal} onExited={this.onExitModal} centered={true} >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.selectedDate}&nbsp;&nbsp;{newSchedTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {/*<Form.Group >*/}
                        {/*    <Form.Label>Name: </Form.Label>*/}
                        {/*    <Form.Control type="text" autoFocus={true} value={this.state.name} placeholder="name input"/>*/}
                        {/*</Form.Group>*/}

                        <div style={{fontSize: '14px'}}>
                            <label htmlFor="selectedHours" style={{fontSize: '16px', marginLeft: '20px', marginTop: marginTop, marginBottom: marginTop, width: '120px'}}>
                            {this.state.selectedHours || ''}
                            </label>
{/*                            <Dropdown
                                style={{fontSize: '16px', marginLeft: marginLeft, marginTop: marginTop, marginBottom: marginTop}}
                                placeholder={hourGuide}
                                value={this.state.selectedHours || ''}
                                // text={this.state.startStr || ''}
                                onChange={this.onSelectedHours}
                                // simple item floating selection
                                floating
                                options={hourOptions}
                                // defaultValue={selectedHour}
                                // style={{width: '200px'}}
                            />*/}

                            {fields.map((field, idx) => (
                                <TextField
                                    key={idx}
                                    style={{marginLeft: field.marginLeft, marginBottom: field.marginBottom, width: field.width}}
                                    required={field.required}
                                    name={field.dataType}
                                    id={field.title}
                                    type={field.type}
                                    label={field.titleKor}
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{datatype: field.dataType}}
                                    placeholder={field.placeholder}
                                    error={this.state.errorState[field.title]}
                                    helperText={this.state.errorMsg[field.title]}
                                    onChange={this.handleInputChange}
                                    onBlur={this.validateInput}
                                    // InputProps={{
                                    //     maxLength : field.maxLen,
                                    // }}
                                    value={this.state.formValues[field.title]}
                                    inputRef={ref => (this.elemRef[`${idx}`] = ref)}
                                />
                            ))}

                            {/*<label htmlFor="emails">Email address</label>*/}
{/*                            <Input type="text" className="form-control" id="fullName"
                                   value={this.state.fullName}
                                   onChange={this.onFullName}
                                   inputRef={this.modalInput}
                                // ref={(Input) => { this.modalInput = Input; }}
                                   placeholder={newFullNameGuide}
                                   style={{width: '100px', marginLeft: '15px' }}
                            />

                            <TextField
                                name={newPhoneNumber}
                                // style={AllStyles.fontTable}
                                variant="outlined"
                                type='text'
                                required={true}
                                size="small"
                                // fullWidth
                                id={this.state.phoneNumber}
                                label={newPhoneNumber}
                                // onChange={this.handleChange}
                                style={{width: '150px', marginLeft: '15px' }}
                            />
                            <Input type="text" className="form-control" id="phoneNumber"
                                   value={this.state.phoneNumber}
                                   onChange={this.onPhoneNumber}
                                // ref={(Input) => { this.modalInput = Input; }}
                                   placeholder={newPhoneNumber}
                                   style={{width: '150px', marginLeft: '15px' }}
                            />

                            <Input type="text" className="form-control" id="email"
                                   value={this.state.schedInput}
                                   onChange={this.onSchedInput}
                                   placeholder={newSchedGuide}
                                   style={{width: '200px', marginLeft: '15px' }}
                            />*/}
                        </div>

                    </Modal.Body>
                    {/*<div className="form-group">*/}
                    {/*    <label htmlFor="recipient-name" className="col-form-label">Recipient:</label>*/}
                    {/*    <input type="text" className="form-control" id="recipient-name"></input>*/}

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.onCancelSched} style={AllStyles.modalButton} >
                            {closeButton}
                        </Button>
                        <Button variant="primary" onClick={this.onSaveSched} style={AllStyles.modalButton}>
                            {saveButton}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*<p style={{fontSize: '22px', marginLeft: '30px'}}>{schedGuide}</p>*/}
            </div>
            </>
        )
    }

    renderSidebar() {
        return (
            <div className='demo-app-sidebar'>
                <div className='demo-app-sidebar-section'>
                        {this.state.currentEvents.map(renderSidebarEvent)}
                </div>
            </div>
        )
    }

    handleWeekendsToggle = () => {
        this.setState({
            weekendsVisible: !this.state.weekendsVisible
        })
    }

    handleChange = (eventInfo) => {
        let calData = {
            id: eventInfo.event.id,
            title: eventInfo.event.title,
            start: eventInfo.event.start,
            end: eventInfo.event.end,
            allDay: 0
        };
        this.setState({calData: calData});

        console.log('[Frontend] Calendar 1 eventResize handleChange: ' + JSON.stringify(calData, null, 4));
        this.addOrModify(1, showSched);
    }

    handleInputChange = (event : any) => {
        event.preventDefault();
        let {name, id, value} = event.target;
        // let errors = this.state.errors;
        // let formValues = this.state.formValues;
        console.log('[Frontend] Signup handleChange event.target id: ' + id + ', name: ' + name + ', value: ' + value);
        console.log('[Frontend] Signup handleChange email confirm: ' + fieldMapping[field_state.FIELD_EMAILCONFIRM]);

        switch (id) {
            case 'phone':
                let utils = new Utils();
                value = utils.insertHyphens(value);
                break;
        }

        this.setState(prevState => {
            let formValues = Object.assign({}, prevState.formValues);
            formValues[`${id}`] = value;
            return {formValues};
        })
    }

    handleDateSelect = (selectInfo) => {
        // let title = prompt('Please enter a new title for your event')
        let currView = selectInfo.view;
        let calendarApi = selectInfo.view.calendar;
        let sample = 'Phonics 놀이교실';

        calendarApi.unselect(); // clear date selection

        // let calendarApi = this.calendarRef.current.getApi();
        // this.props.history.push('/');

        console.log("[Frontend] Calendar 1 select currData: " + JSON.stringify(currView, null, 4));
        console.log("[Frontend] Calendar 1 select handleDateSelect: " + JSON.stringify(selectInfo.startStr, null, 4));

        // "startStr": "2020-12-24T08:00:00+09:00",
        // "endStr": "2020-12-24T09:00:00+09:00",

        /*if (currView.type === weekView) {
            // let title = prompt('Please enter a new title for your event')

            let currData = calendarApi.getEvents(); //   getCurrentData()
            console.log('[Frontend] TimeTable handleDateSelect: ' + JSON.stringify(currData, null, 4));

            let matchIndex = -1;
            currData.forEach(function(obj, index) {
                if (obj.start === selectInfo.startStr) {
                    matchIndex = index;
                }
                console.log('[Frontend] TimeTable handleDateSelect obj.start: ' + obj.start + ', matchIndex?: ' + matchIndex);
            });

/!*            currData.forEach(obj => {
                /!*         Object.entries(obj).forEach(([key, value]) => {
                            console.log(`${key} ${value}`);
                        }); *!/
                if (obj.start === selectInfo.startStr) {
                    isMatch = true;
                }
                console.log('[Frontend] TimeTable handleDateSelect obj.start: ' + obj.start + ', isMatch?: ' + isMatch);
            });*!/

            if (matchIndex >= 0) {
                let calData = {
                    title: sample,
                    start: selectInfo.startStr,
                    end: currData[matchIndex].end,
                    // end: selectInfo.endStr,
                    allDay: (selectInfo.allDay) ? 1 : 0
                };
                this.setState({calData: calData});
                // moment.locale('ko')
                moment.locale('ko', {
                    weekdays: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
                    weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
                });
                let currDate = moment(calData.start);
                let currDateEnd = moment(calData.end);
                // currDate.locale('ko');
                // let currDateKr = moment(calData.start, 'ko');
                // currDateKr.locale('ko')

                let currDateMD = currDate.format("MM/DD dddd")
                let currHour = currDate.hour();
                let currHourEnd = currDateEnd.hour();
                let currHourRange = getHourRangeEnd(currHour, currHourEnd);
                let currHourRange24 = getHourRange24(currHour);

                this.setState({
                    selectedDate: currDateMD,
                    selectedHours: currHourRange,
                    startStr: currHourRange24[0],
                    endStr: currHourRange24[1],
                    currHour: currHour
                });

                /!*            calendarApi.addEvent({
                                id: createEventId(),
                                // calData
                                title: sample,
                                start: selectInfo.startStr,
                                end: selectInfo.endStr,
                                allDay: selectInfo.allDay
                            });*!/
                console.log("[Frontend] Calendar 1 select insert DB: " + JSON.stringify(calData, null, 4));

                this.toggleModal(0, saveMode);
            }
        }
        else */
        if (currView.type === monthView) {
            calendarApi.changeView(weekView, selectInfo.startStr); // timeGridWeek  timeGridDay
        }

        /*if (title) {
          calendarApi.addEvent({
            id: createEventId(),
            title,
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay
          })
        }*/
    }

    handleEventClick = (clickInfo) => {
        let currView = clickInfo.view;
        let calendarApi = clickInfo.view.calendar;

        if (currView.type === weekView) {
            let calData = {
                id: clickInfo.event.id,
                title: clickInfo.event.title,
                // start: clickInfo.event.start,
                // end: clickInfo.event.end,
                allDay: 0,
                canReserve: clickInfo.event.extendedProps.canReserve,
                eventDate: '',
                eventStartTime: '',
                eventEndTime: ''
            };

            // this.setState({calData: calData});
            // let currDateKr = moment(calData.start, 'ko');
            // currDateKr.locale('ko')
            console.log("[Frontend] Calendar 1 select currData: " + JSON.stringify(clickInfo.event, null, 4));

            if (calData.canReserve) {
                moment.locale('ko', {
                    weekdays: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
                    weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
                });
                let currDate = moment(clickInfo.event.start);
                let currDateEnd = moment(clickInfo.event.end);
                // currDate.locale('ko')
                let currDateMD = currDate.format("MM/DD dddd")
                let currHour = currDate.hour();
                let currHourEnd = currDateEnd.hour();

                let currHourRange = getHourRangeEnd(currHour, currHourEnd);
                // let currHourRange = getHourRange(currHour);
                let currHourRange24 = getHourRange24(currHour);

                calData.start = currDate.format("YYYY-MM-DD HH:mm:ss");
                calData.end = currDateEnd.format("YYYY-MM-DD HH:mm:ss");
                console.log('[Frontend] Calendar 1 back handleEventClick: ' + JSON.stringify(calData, null, 4));

                console.log("[Frontend] Calendar 1 select currHour: " + currHour);
                this.setState({
                    calData: calData,
                    schedInput: calData.title,
                    selectedDate: currDateMD,
                    selectedHours: currHourRange,
                    startStr: currHourRange24[0],
                    endStr: currHourRange24[1]
                });
                this.toggleModal(0, saveMode);
                // sonic: changed to saveMode to support only
            }
        }
        if (currView.type === monthView) {
            calendarApi.changeView(weekView, clickInfo.event.startStr); // timeGridWeek  timeGridDay
        }
        // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //   clickInfo.event.remove()
        // }
    }

    handleEvents = (events) => {
        this.setState({
            currentEvents: events
        })
    }

}

function handleDrag(eventInfo) {
    console.log('[Frontend] Calendar 1 eventResize handleResize: ' + JSON.stringify(eventInfo, null, 4));
}

function handleResize(eventInfo) {
    console.log('[Frontend] Calendar 1 eventResize handleResize: ' + JSON.stringify(eventInfo, null, 4));
}

function renderEventContent(eventInfo) {
    // console.log('[Frontend] Calendar 1 eventContent: ' + JSON.stringify(eventInfo, null, 4));
    return (
        <div className="fc-content" style={{fontSize: '14px'}}>
           <b>{eventInfo.timeText}
            &emsp;{eventInfo.event.title}</b>
        </div>
    )
}

function renderSidebarEvent(event) {
    return (
        <li key={event.id}>
            <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
            <i>{event.title}</i>
        </li>
    )
}
